import { ChainId } from '@pancakeswap/chains'
import { BoxProps, ChevronDownIcon, Flex, FlexGap, Row, Text } from '@pancakeswap/uikit'
import { ChainLogo } from 'components/Logo/ChainLogo'
import { CHAINS_BTC } from 'config/chains'
import { SUPPORT_CROSS_CHAIN } from 'config/constants/supportChains'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { chainNameConverter } from 'utils/chainNameConverter'
import { Chain } from 'viem'

const DropDownListContainer = styled(Flex)`
  height: 0;
  position: absolute;
  right: 0;
  top: 100%;
  transform: scaleY(0) translateX(0);
  z-index: 10;

  overflow: hidden;
  background: rgba(34, 36, 38, 0.66);
  transition: transform 0.15s, opacity 0.15s;

  transform-origin: top;
  min-width: 168px;
  border-radius: 8px;

  ${({ theme }) => theme.mediaQueries.md} {
    top: 0;
    transform: scaleY(0) translateX(100%);
  }
`

const DropDownContainer = styled(Flex)<{ $isOpen: boolean }>`
  cursor: pointer;
  align-items: center;
  position: relative;
  border-radius: 25px;
  user-select: none;

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      ${DropDownListContainer} {
        height: auto;
        transform: scaleY(1) translateX(0);
        opacity: 1;
        border: 1px solid ${({ theme }) => theme.colors.text};

        ${({ theme }) => theme.mediaQueries.md} {
          transform: scaleY(1) translateX(100%);
        }
      }
    `}

  svg {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
`

const DropDownList = styled.ul`
  width: 100%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  z-index: 9;
`

const ListItem = styled.li`
  list-style: none;
  padding: 8px 16px;

  &:hover {
    background: ${({ theme }) => theme.colors.primary};
  }
`

const FixedBackground = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 100%;
  z-index: 9;
`

const StyledSelectChain = styled(FlexGap)`
  cursor: pointer;
  transform: scale(1);
  transition: all 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  width: max-content;

  &:hover {
    transform: scale(1.02);
  }

  img {
    width: clamp(18px, 3vw, 24px);
  }
`

const SelectChainItem: React.FC<{ chain: Chain; color?: string }> = ({ chain, color = 'white' }) => {
  return (
    chain && (
      <StyledSelectChain gap="8px" alignItems="center">
        <ChainLogo chainId={chain.id} width={36} height={36} />
        <Text fontWeight={500} whiteSpace="nowrap" fontSize="clamp(10px, 2vw, 16px)" color={color}>
          {chainNameConverter(chain.name)}
        </Text>
      </StyledSelectChain>
    )
  )
}

const ChainSelect: React.FunctionComponent<
  BoxProps & {
    selectedChainId?: ChainId
    onSelectChainId?: (chainId: ChainId) => void
    dropdownContainer?: BoxProps
  }
> = ({ selectedChainId, onSelectChainId, dropdownContainer, ...props }) => {
  const dropdownRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)

  const toggling = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      setIsOpen(!isOpen)
      event.stopPropagation()
    },
    [isOpen],
  )
  const handleClickOutside = useCallback(() => setIsOpen(false), [])

  useEffect(() => {
    window.addEventListener('click', handleClickOutside)
    return () => {
      window.removeEventListener('click', handleClickOutside)
    }
  }, [handleClickOutside])

  const chainsMap = useMemo(
    () =>
      CHAINS_BTC.reduce((state, chain) => {
        state[chain.id] = chain
        return state
      }, {}),
    [],
  )

  const renderContent = useMemo(() => {
    return SUPPORT_CROSS_CHAIN.filter((chain) => {
      if ('testnet' in chainsMap[chain] && chainsMap[chain].testnet) {
        return process.env.NODE_ENV !== 'production'
      }
      return true
    })?.map((id) => (
      <ListItem
        onClick={() => {
          setIsOpen(false)
          if (onSelectChainId) {
            onSelectChainId(id)
          }
        }}
        key={id}
      >
        <SelectChainItem chain={chainsMap[id]} />
      </ListItem>
    ))
  }, [chainsMap, onSelectChainId])

  return (
    <DropDownContainer $isOpen={isOpen} {...props}>
      {isOpen ? <FixedBackground /> : null}
      <Row alignItems="center" pr="32px" pl="16px" onClick={toggling}>
        {selectedChainId ? (
          <SelectChainItem chain={chainsMap[selectedChainId]} color="black" />
        ) : (
          <Text fontSize="clamp(10px, 2vw, 16px)" color="black">
            Select Network
          </Text>
        )}
        <ChevronDownIcon color="black" onClick={toggling} />
      </Row>
      <DropDownListContainer {...dropdownContainer}>
        <DropDownList ref={dropdownRef}>{renderContent}</DropDownList>
      </DropDownListContainer>
    </DropDownContainer>
  )
}

export default ChainSelect
