import { isChainZeta } from '@pancakeswap/chains'
import { ChainId, Native } from '@pancakeswap/sdk'
import { Currency, CurrencyAmount, ZERO } from '@pancakeswap/swap-sdk-core'
import BigNumber from 'bignumber.js'
import { zrc20ABI } from 'config/abi/zrc20'
import { TokenForeignTypeEnums } from 'config/constants/cross-swap'
import { SUPPORT_CROSS_CHAIN } from 'config/constants/supportChains'
import { getForeignCoins } from 'hooks/Tokens'
import { getZrc20ByCurrency } from 'state/crossSwap/types'
import { getMulticallAddress } from 'utils/addressHelpers'
import { getZetaChainByAnotherChain, isChainTestnet, publicClient } from 'utils/wagmi'
import { Abi, Address } from 'viem'

type FeeByChains = {
  [key in ChainId]: CurrencyAmount<Currency>
}

export const fetchNativeFees = async (chainId: ChainId): Promise<FeeByChains> => {
  const calls = SUPPORT_CROSS_CHAIN.map((chain) => {
    const address =
      isChainTestnet(chainId) === isChainTestnet(chain) &&
      !isChainZeta(chain) &&
      getZrc20ByCurrency(Native.onChain(chain))?.address

    return {
      chain,
      request: {
        address: address as Address,
        functionName: 'withdrawGasFee',
        abi: zrc20ABI,
      },
    }
  }).filter((item) => item.request.address)

  const projectChain = getZetaChainByAnotherChain(chainId)
  const requests = await publicClient({ chainId: projectChain })?.multicall({
    // @ts-ignore
    contracts: calls.map((call) => call.request),
    multicallAddress: getMulticallAddress(projectChain),
  })

  return (requests || []).reduce((state: FeeByChains, response: any, index) => {
    const { chain } = calls[index]

    if (!response || !chain) return state

    const withdrawGasFee = (response?.result?.[1] as BigNumber) || ZERO

    state[chain] = CurrencyAmount.fromRawAmount(Native.onChain(chain), withdrawGasFee.toString())
    return state
  }, {} as unknown as FeeByChains)
}

export const fetchZrc20Fee = async (chainId: ChainId): Promise<{ [key in ChainId]: CurrencyAmount<Currency> }> => {
  const tokens = getForeignCoins(chainId).filter((token) => token.type === TokenForeignTypeEnums.ERC20)
  const calls = tokens
    .map((token) => {
      return {
        chain: token.foreignChainId,
        request: {
          address: token.zrc20Address,
          functionName: 'withdrawGasFee',
          abi: zrc20ABI as Abi,
        },
      }
    })
    .filter((item) => item.request.address)

  const projectChain = getZetaChainByAnotherChain(chainId)
  const requests = await publicClient({ chainId: projectChain })?.multicall({
    contracts: calls.map((call) => call.request),
    multicallAddress: getMulticallAddress(projectChain),
  })

  return (requests || []).reduce((state, response, index) => {
    const { chain } = calls[index]
    const token = getZrc20ByCurrency(Native.onChain(chain))

    if (!response || !response?.result || !token) return state

    const withdrawGasFee = (response?.result?.[1] as BigNumber) || ZERO

    state[chain] = CurrencyAmount.fromRawAmount(token, withdrawGasFee.toString())

    return state
  }, {} as unknown as FeeByChains)
}

export const fetchFees = async (chainId: ChainId) => {
  const nativeFees = await fetchNativeFees(chainId)
  const zrc20Fee = await fetchZrc20Fee(chainId)
  return {
    feesZRC20: zrc20Fee,
    feesZNative: nativeFees,
  }
}
