import { ChainId, isChainBitcoin } from '@pancakeswap/chains'
import { Native } from '@pancakeswap/sdk'
import { Currency, CurrencyAmount, Token } from '@pancakeswap/swap-sdk-core'
import { TokenForeignTypeEnums } from 'config/constants/cross-swap'
import { PUBLIC_NODES } from 'config/nodes'
import { ethers } from 'ethers'
import { getForeignCoins } from 'hooks/Tokens'
import { getZetaChainByAnotherChain, isChainTestnet } from 'utils/wagmi'
import { erc20Abi } from 'viem'
import { getEsplora } from './endpoints'

export const getBitcoinBalance = async (address: string, chainId: ChainId): Promise<CurrencyAmount<Currency>> => {
  try {
    const endpoint = getEsplora(isChainTestnet(chainId) ? ChainId.BTC_TESTNET : ChainId.BTC_MAINNET)
    const data = await fetch(`${endpoint}/address/${address}`)
      .then((response) => response.json())
      .catch((e) => e)

    if (data?.address) {
      const { funded_txo_sum: fundedTxoSum, spent_txo_sum: spentTxoSum } = data.chain_stats
      return CurrencyAmount.fromRawAmount(Native.onChain(chainId), fundedTxoSum - spentTxoSum)
    }
    return CurrencyAmount.fromRawAmount(Native.onChain(chainId), 0)
  } catch {
    return CurrencyAmount.fromRawAmount(Native.onChain(chainId), 0)
  }
}

export const getEtherBalance = (address: string, currency: Currency): Promise<CurrencyAmount<Currency>> => {
  try {
    const rpc = PUBLIC_NODES[currency.chainId][0] // TODO getRpcRandom
    const provider = new ethers.providers.JsonRpcProvider(rpc)
    return provider
      .getBalance(address)
      .then((balance) => {
        return CurrencyAmount.fromRawAmount(currency, balance.toString())
      })
      .catch(() => {
        return CurrencyAmount.fromRawAmount(currency, 0)
      })
  } catch {
    return new Promise((resolve) => resolve(CurrencyAmount.fromRawAmount(currency, 0)))
  }
}

export const getErc20Balance = (address: string, currency: Token) => {
  try {
    const rpc = PUBLIC_NODES[currency.chainId][0]
    const provider = new ethers.providers.JsonRpcProvider(rpc)
    const contract = new ethers.Contract(currency.address, erc20Abi, provider)
    return contract
      .balanceOf(address)
      .then((balance) => {
        return CurrencyAmount.fromRawAmount(currency, balance.toString())
      })
      .catch(() => {
        return CurrencyAmount.fromRawAmount(currency, 0)
      })
  } catch {
    return CurrencyAmount.fromRawAmount(currency, 0)
  }
}

export const getBalances = async (chainId: ChainId, address = '', btcAddress = '') => {
  const foreignCoins = getForeignCoins(chainId)

  const chain = getZetaChainByAnotherChain(chainId)
  const tokens: (Currency | Token)[] = [Native.onChain(chain)]

  foreignCoins.forEach((token: any) => {
    const foreignChainId = parseInt(token.foreignChainId, 10)

    if (token.type === TokenForeignTypeEnums.Gas) {
      try {
        const native = Native.onChain(foreignChainId)
        tokens.push(native)
        tokens.push(new Token(chain, token.zrc20Address, token.decimals, native.symbol, native.name))
      } catch {
        // eslint-disable-next-line no-empty
      }
    } else if (token.type === TokenForeignTypeEnums.ERC20) {
      tokens.push(new Token(foreignChainId, token.address, token.decimals, token.symbol, token.name))
      tokens.push(new Token(chain, token.zrc20Address, token.decimals, token.symbol, token.name))
    }
  })

  const balances: CurrencyAmount<Currency>[] = await Promise.all(
    tokens
      .map(async (token: Currency | Token) => {
        const isGas = token?.isNative
        const isBitcoin = isChainBitcoin(token.chainId)

        if (isGas && !isBitcoin && address) {
          return getEtherBalance(address, token)
        }
        if (isGas && isBitcoin && btcAddress) {
          return getBitcoinBalance(btcAddress, chainId)
        }

        if (token?.isToken && address) {
          return getErc20Balance(address, token)
        }

        return null
      })
      .filter((token) => token),
  )
  return balances.filter((token) => token)
}

// update multicall for each chains
// const calls = currencies
//     .map((currency) => {
//       if (isChainBitcoin(currency.chainId)) return {}

//       if (currency.isNative) {
//         return {
//           currency,
//           request: {
//             address: getMulticallAddress(currency.chainId) as Address,
//             functionName: 'getEthBalance',
//             abi: erc20Abi,
//             args: [address],
//           },
//         }
//       }

//       return {
//         currency,
//         request: {
//           address: currency.address as unknown as Address,
//           functionName: 'balanceOf',
//           abi: erc20Abi,
//           args: [address],
//         },
//       }
//     })
//     .filter((item) => item?.request)

//   const projectChain = getZetaChainByAnotherChain(chainId)
//   const requests = await publicClient({ chainId: projectChain })?.multicall({
//     contracts: calls.map((call) => call.request) as any,
//     multicallAddress: getMulticallAddress(projectChain),
//   })

//   if (!requests) return []

//   const btcBalance = await getBitcoinBalance(btcAddress, chainId)
//   const balances: CurrencyAmount<Currency>[] = requests.map((response, index) => {
//     try {
//       return CurrencyAmount.fromRawAmount(calls[index].currency as Currency, response.result.toString())
//     } catch {
//       return CurrencyAmount.fromRawAmount(calls[index].currency as Currency, 0)
//     }
//   })

//   return [btcBalance, ...balances.filter((token) => token)]
