import { isChainBitcoin } from '@pancakeswap/chains'
import { Currency, CurrencyAmount, Native, Token } from '@pancakeswap/sdk'
import { multicallABI } from 'config/abi/Multicall'
import { FAST_INTERVAL } from 'config/constants'
import { useAllTokens } from 'hooks/Tokens'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { useBitcoinBalance } from 'hooks/useBitcoinBalance'
import useNativeCurrency from 'hooks/useNativeCurrency'
import orderBy from 'lodash/orderBy'
import { useMemo } from 'react'
import { safeGetAddress } from 'utils'
import { getMulticallAddress } from 'utils/addressHelpers'
import { config } from 'utils/wagmi'
import { Address, erc20Abi, isAddress, zeroAddress } from 'viem'
import { useAccount, useReadContracts } from 'wagmi'
import { useMultipleContractSingleData, useSingleContractMultipleData } from '../multicall/hooks'

/**
 * Returns a map of the given addresses to their eventually consistent BNB balances.
 */
export function useNativeBalances(uncheckedAddresses?: (string | undefined)[]): {
  [address: string]: CurrencyAmount<Native> | undefined
} {
  const native = useNativeCurrency()

  const addresses: Address[] = useMemo(
    () =>
      uncheckedAddresses
        ? orderBy(uncheckedAddresses.map(safeGetAddress).filter((a): a is Address => a !== undefined))
        : [],
    [uncheckedAddresses],
  )

  const results = useSingleContractMultipleData({
    contract: useMemo(
      () => ({
        abi: multicallABI,
        address: getMulticallAddress(native.chainId),
      }),
      [native],
    ),
    functionName: 'getEthBalance',
    args: useMemo(() => addresses.map((address) => [address] as const), [addresses]),
  })

  return useMemo(
    () =>
      addresses.reduce<{ [address: string]: CurrencyAmount<Native> }>((memo, address, i) => {
        const value = results?.[i]?.result
        if (value && typeof value !== 'undefined')
          memo[address] = CurrencyAmount.fromRawAmount(native, BigInt(value.toString()))
        return memo
      }, {}),
    [addresses, results, native],
  )
}

/**
 * Returns a map of token addresses to their eventually consistent token balances for a single account.
 */
export function useTokenBalancesWithLoadingIndicator(
  address?: string,
  tokens?: (Token | undefined)[],
): [{ [tokenAddress: string]: CurrencyAmount<Token> | undefined }, boolean] {
  const validatedTokens: Token[] = useMemo(
    () => tokens?.filter((t?: Token): t is Token => !!t && isAddress(t.address)) ?? [],
    [tokens],
  )

  const validatedTokenAddresses = useMemo(() => validatedTokens.map((vt) => vt.address), [validatedTokens])

  const balances = useMultipleContractSingleData({
    abi: erc20Abi,
    addresses: validatedTokenAddresses,
    functionName: 'balanceOf',
    args: useMemo(() => [address as Address] as const, [address]),
    options: {
      enabled: Boolean(address && validatedTokenAddresses.length > 0),
    },
  })

  const anyLoading: boolean = useMemo(() => balances.some((callState) => callState.loading), [balances])

  return [
    useMemo(
      () =>
        address && validatedTokens.length > 0
          ? validatedTokens.reduce<{ [tokenAddress: string]: CurrencyAmount<Token> | undefined }>((memo, token, i) => {
              const value = balances?.[i]?.result
              const amount = value && typeof value !== 'undefined' ? BigInt(value.toString()) : undefined
              if (typeof amount !== 'undefined') {
                memo[token.address] = CurrencyAmount.fromRawAmount(token, amount)
              }
              return memo
            }, {})
          : {},
      [address, validatedTokens, balances],
    ),
    anyLoading,
  ]
}

export function useTokenBalances(
  address?: string,
  tokens?: (Token | undefined)[],
): { [tokenAddress: string]: CurrencyAmount<Token> | undefined } {
  return useTokenBalancesWithLoadingIndicator(address, tokens)[0]
}

// get the balance for a single token/account combo
export function useTokenBalance(account?: string, token?: Token): CurrencyAmount<Token> | undefined {
  const tokenBalances = useTokenBalances(
    account,
    useMemo(() => [token], [token]),
  )
  if (!token) return undefined
  return tokenBalances[token.address]
}

export function useCurrencyBalances(
  account?: string,
  currencies?: (Currency | undefined | null)[],
): (CurrencyAmount<Currency> | undefined)[] {
  const tokens = useMemo(
    () => currencies?.filter((currency): currency is Token => !!currency?.isToken) ?? [],
    [currencies],
  )

  const tokenBalances = useTokenBalances(account, tokens)
  const containsNative: boolean = useMemo(
    () => currencies?.some((currency) => currency?.isNative) ?? false,
    [currencies],
  )
  const uncheckedAddresses = useMemo(() => (containsNative ? [account] : []), [containsNative, account])
  const nativeBalance = useNativeBalances(uncheckedAddresses)

  return useMemo(
    () =>
      currencies?.map((currency) => {
        if (!account || !currency) return undefined
        if (currency?.isToken) return tokenBalances[currency.address]
        if (currency?.isNative) return nativeBalance[account]
        return undefined
      }) ?? [],
    [account, currencies, nativeBalance, tokenBalances],
  )
}

export function useCurrencyBalance(account?: string, currency?: Currency | null): CurrencyAmount<Currency> | undefined {
  return useCurrencyBalances(
    account,
    useMemo(() => [currency], [currency]),
  )[0]
}

// mimics useAllBalances
export function useAllTokenBalances(): { [tokenAddress: string]: CurrencyAmount<Token> | undefined } {
  const { address: account } = useAccount()
  const allTokens = useAllTokens()
  const allTokensArray = useMemo(() => Object.values(allTokens ?? {}), [allTokens])
  const balances = useTokenBalances(account ?? undefined, allTokensArray)
  return balances ?? {}
}

export function useCurrencyCrossChainBalances(
  account: Address | undefined,
  bitcoinAccount: string | undefined,
  currencies: (Currency | undefined)[],
): (CurrencyAmount<Currency> | undefined)[] {
  const { chainId } = useActiveChainId()

  const containsBTC: boolean = useMemo(
    () => currencies?.some((currency) => currency && isChainBitcoin(currency.chainId)) ?? false,
    [currencies],
  )

  const calls = useMemo(() => {
    return currencies.map((currency) => {
      if (!currency || isChainBitcoin(currency.chainId)) {
        return {
          abi: [],
          address: zeroAddress,
          args: [],
          chainId: 0,
          functionName: '',
        }
      }
      if (currency?.isNative) {
        return {
          abi: multicallABI,
          address: getMulticallAddress(currency.chainId),
          args: [account],
          chainId: currency.chainId,
          functionName: 'getEthBalance',
        }
      }
      return {
        abi: erc20Abi,
        address: (currency as Token).address,
        args: [account],
        chainId: currency.chainId,
        functionName: 'balanceOf',
      }
    }, {})
  }, [account, currencies])

  // @ts-ignore
  const { data } = useReadContracts({
    contracts: calls,
    allowFailure: false,
    config,
    query: {
      refetchInterval: FAST_INTERVAL,
      cacheTime: FAST_INTERVAL,
    },
  })

  const bitcoinBalance = useBitcoinBalance(containsBTC ? bitcoinAccount : undefined, chainId)

  return useMemo(
    () =>
      currencies?.map((currency, index) => {
        if (!currency) return undefined

        if (isChainBitcoin(currency.chainId)) {
          return CurrencyAmount.fromRawAmount(currency, bitcoinBalance)
        }

        return data && CurrencyAmount.fromRawAmount(currency, data?.[index].toString())
      }) ?? [],
    [bitcoinBalance, currencies, data],
  )
}

export function useCurrencyCrossChainBalance(
  account?: Address,
  bitcoinAccount?: string,
  currency?: Currency,
): CurrencyAmount<Currency> | undefined {
  return useCurrencyCrossChainBalances(
    account,
    bitcoinAccount,
    useMemo(() => [currency], [currency]),
  )[0]
}
