import { isChainBitcoin, isChainZeta } from '@pancakeswap/chains'
import { useTranslation } from '@pancakeswap/localization'
import { ChainId, Currency, CurrencyAmount, Native } from '@pancakeswap/sdk'
import { getDecimalAmount } from '@pancakeswap/utils/formatBalance'
import { toBigInt } from '@pancakeswap/utils/toBigInt'
import tryParseAmount from '@pancakeswap/utils/tryParseAmount'
import BigNumber from 'bignumber.js'
import { pancakeRouter02ABI } from 'config/abi/IPancakeRouter02'
import { erc20CustodyABI } from 'config/abi/erc20Custory'
import { omniABI } from 'config/abi/omniAbi'
import { zrc20ABI } from 'config/abi/zrc20'
import { FAST_INTERVAL, SLOW_INTERVAL } from 'config/constants'
import { ActionCrossChainSwap, ActionCrossChainSwapBTC, MethodCrossSwap } from 'config/constants/cross-swap'
import {
  DEFAULT_INPUT_CHAIN_ID,
  DEFAULT_INPUT_CURRENCY,
  DEFAULT_OUTPUT_CHAIN_ID,
  DEFAULT_OUTPUT_CURRENCY,
  V2_ROUTER_ADDRESS,
} from 'config/constants/exchange'
import { SUPPORT_CROSS_CHAIN } from 'config/constants/supportChains'
import { getBytesAddress } from 'contexts/ZetaContext/helpers/address'
import { useBitcoinAccount } from 'contexts/ZetaContext/hook'
import { ethers } from 'ethers'
import { FEE_RATE } from 'hooks/WalletBitcoinProvider/connectors/base'
import { useWalletBitcoinProviderByWallet } from 'hooks/WalletBitcoinProvider/useWalletBitcoinProviders'
import { useActiveChainId } from 'hooks/useActiveChainId'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useLlamaCurrencyPrice } from 'hooks/useBUSDPrice'
import { useAtom, useAtomValue } from 'jotai'
import { useRouter } from 'next/router'
import { ParsedUrlQuery } from 'querystring'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAddTransactionCrossSwap } from 'state/crossSwapTransactions/hooks'
import { CrossChainStatus } from 'state/crossSwapTransactions/types'
import { prepareData } from 'state/crossSwapTransactions/utils'
import { Field } from 'state/swap/actions'
import { safeGetAddress } from 'utils'
import { clear0xAddress, getErc20CustodyAddress, getOmniChainAddress, getTSSAddress } from 'utils/addressHelpers'
import { multiplyPriceByAmount } from 'utils/prices'
import { config, getBTCChainByAnotherChain, getZetaChainByAnotherChain } from 'utils/wagmi'
import { Address, zeroAddress } from 'viem'
import { useAccount, useEstimateGas, useReadContract, useReadContracts } from 'wagmi'
import { sendTransaction } from 'wagmi/actions'
import { useCurrencyCrossChainBalances } from '../wallet/hooks'
import { replaceCrossSwapState, selectCurrency, setRecipient, switchCurrencies, typeInput } from './actions'
import { CrossSwapState, crossSwapReducerAtom } from './reducer'
import { CrossSwapTrade, getMethodCrossChain, getZrc20ByCurrency, isBTC, validateSameToken } from './types'

export const useCrossSwapState = () => {
  return useAtomValue(crossSwapReducerAtom)
}

// from the current swap inputs, compute the best trade and return it.
export function useDerivedCrossSwapInfo(
  independentField: Field,
  typedValue: string,
  inputCurrency?: Currency,
  outputCurrency?: Currency,
): {
  currencies: { [field in Field]?: Currency }
  currencyBalances: { [field in Field]?: CurrencyAmount<Currency> }
  parsedAmount: CurrencyAmount<Currency> | undefined
  trade: CrossSwapTrade
  feeError?: string
  inputError?: string
  fee: number
} {
  const { address: account } = useAccount()
  const bitcoinAccount = useBitcoinAccount()
  const { recipient } = useCrossSwapState()

  const { t } = useTranslation()

  const relevantTokenBalances = useCurrencyCrossChainBalances(
    account as Address,
    bitcoinAccount,
    useMemo(() => [inputCurrency ?? undefined, outputCurrency ?? undefined], [inputCurrency, outputCurrency]),
  )

  const isExactIn: boolean = independentField === Field.INPUT
  const parsedAmount = tryParseAmount(typedValue, (isExactIn ? inputCurrency : outputCurrency) ?? undefined)

  const trade = useCrossChainTrade(
    account as Address,
    bitcoinAccount,
    recipient || '',
    independentField,
    typedValue,
    inputCurrency,
    outputCurrency,
  )

  const currencyBalances = {
    [Field.INPUT]: relevantTokenBalances[0],
    [Field.OUTPUT]: relevantTokenBalances[1],
  }

  const [balanceIn, amountIn, amountOut] = [
    currencyBalances[Field.INPUT],
    trade.inputCurrencyAmount,
    trade.outputCurrencyAmount,
  ]

  const currencyLLama = useMemo(() => {
    if (!amountOut) return undefined
    if (isBTC(amountOut?.currency, amountOut?.currency.chainId)) return getZrc20ByCurrency(amountOut.currency)
    return amountOut.currency
  }, [amountOut])

  const { price: priceCurrnecyInput } = useLlamaCurrencyPrice(currencyLLama, {
    chainId: currencyLLama?.chainId,
    enabled: Boolean(currencyLLama?.chainId && trade.methodSwap),
  })

  const amountOutputInUSD = multiplyPriceByAmount(priceCurrnecyInput, Number(amountOut?.toExact() || 0))

  const fee = useFeeCrossSwap(trade)

  const currencies: { [field in Field]?: Currency } = {
    [Field.INPUT]: inputCurrency ?? undefined,
    [Field.OUTPUT]: outputCurrency ?? undefined,
  }

  let inputError: string | undefined
  let feeError: string | undefined

  if (!trade.recipient) {
    inputError = t('Connect Wallet')
  }

  if (!parsedAmount) {
    inputError = inputError ?? t('Enter an amount')
  }

  if (!currencies[Field.INPUT] || !currencies[Field.OUTPUT]) {
    inputError = inputError ?? t('Select a token')
  }

  if (balanceIn && amountIn && balanceIn.lessThan(amountIn)) {
    inputError = t('Insufficient %symbol% balance', { symbol: trade.inputCurrencyAmount?.currency.symbol })
  }

  if (fee && amountOutputInUSD && amountOutputInUSD && fee > amountOutputInUSD) {
    inputError = t('swap')
    feeError = t('Output amount have to greater than fee')
  }

  if (!trade.methodSwap && inputCurrency && outputCurrency) {
    inputError = t('Unsupported Asset')
  }

  return {
    currencies,
    currencyBalances,
    parsedAmount,
    trade,
    fee,
    inputError,
    feeError,
  }
}

export const useCrossChainTrade = (
  evmAccount: Address,
  bitcoinAccount: string,
  recipient: string,
  independentField: Field,
  typedValue: string,
  inputCurrency?: Currency,
  outputCurrency?: Currency,
) => {
  const [routerAddress, parseAmount, parseAmountOut, methodSwap] = useMemo(() => {
    const router = V2_ROUTER_ADDRESS[getZetaChainByAnotherChain(inputCurrency?.chainId)]

    if (!typedValue || Number.isNaN(typedValue) || !inputCurrency || !outputCurrency)
      return [router, toBigInt(0), toBigInt(0), '', '', null]

    const amount = toBigInt(getDecimalAmount(new BigNumber(typedValue), inputCurrency.decimals).toFixed(0))
    const amountOut = toBigInt(getDecimalAmount(new BigNumber(typedValue), outputCurrency.decimals).toFixed(0))

    const method = getMethodCrossChain(inputCurrency, outputCurrency)

    return [router, amount, amountOut, method]
  }, [inputCurrency, outputCurrency, typedValue])

  const isExactIn: boolean = independentField === Field.INPUT

  const path = useMemo(() => {
    if (!inputCurrency || !outputCurrency) return [zeroAddress, zeroAddress]
    const wrapNativeAddress = Native.onChain(getZetaChainByAnotherChain(inputCurrency.chainId)).wrapped.address
    const inputAddress = getZrc20ByCurrency(inputCurrency)?.address
    const outputAddress = getZrc20ByCurrency(outputCurrency)?.address

    if (!inputAddress || !outputAddress) return [zeroAddress, zeroAddress]

    return [inputAddress, outputAddress].includes(wrapNativeAddress)
      ? [inputAddress, outputAddress]
      : [inputAddress, wrapNativeAddress, outputAddress]
  }, [inputCurrency, outputCurrency])

  const { data } = useReadContract({
    abi: pancakeRouter02ABI,
    address: routerAddress as Address,
    functionName: isExactIn ? 'getAmountsOut' : 'getAmountsIn',
    args: [parseAmount, path],
    chainId: inputCurrency && getZetaChainByAnotherChain(inputCurrency.chainId),
    config,
    query: {
      cacheTime: FAST_INTERVAL,
      refetchInterval: FAST_INTERVAL,
      enabled: !!methodSwap,
    },
  })

  return useMemo(() => {
    const result = data as unknown as [any, any]
    const resultIn = result?.[0]?.toString() || ''
    const resultOut = result?.[result.length - 1]?.toString() || ''

    const isSameRate =
      inputCurrency &&
      outputCurrency &&
      validateSameToken({
        inputCurrency,
        outputCurrency,
      })

    const amountIn = isSameRate ? parseAmount : resultIn
    const amountOut = isSameRate ? parseAmountOut : resultOut

    return new CrossSwapTrade({
      inputCurrency,
      inputAmount: amountIn.toString(),

      outputCurrency,
      outputAmount: amountOut.toString(),

      recipient,
      evmAccount,
      bitcoinAccount,
      slippage: 50,
    })
  }, [bitcoinAccount, data, evmAccount, inputCurrency, outputCurrency, parseAmount, parseAmountOut, recipient])
}

function parseTokenAmountURLParameter(urlParam: any): string {
  return typeof urlParam === 'string' && !Number.isNaN(parseFloat(urlParam)) ? urlParam : ''
}

function parseIndependentFieldURLParameter(urlParam: any): Field {
  return typeof urlParam === 'string' && urlParam.toLowerCase() === 'output' ? Field.OUTPUT : Field.INPUT
}

const ENS_NAME_REGEX = /^[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)?$/

const ADDRESS_REGEX = /^0x[a-fA-F0-9]{40}$/
function validatedRecipient(recipient: any): string | null {
  if (typeof recipient !== 'string') return null
  const address = safeGetAddress(recipient)
  if (address) return address
  if (ENS_NAME_REGEX.test(recipient)) return recipient
  if (ADDRESS_REGEX.test(recipient)) return recipient
  return null
}

export function queryParametersToCrossSwapState(parsedQs: ParsedUrlQuery, chainId: ChainId): CrossSwapState {
  const isChainSupported = SUPPORT_CROSS_CHAIN.includes(chainId)
  const inputChain = ChainId[Number(parsedQs.inputChain)]
    ? Number(parsedQs.inputChain)
    : isChainSupported
    ? chainId
    : DEFAULT_INPUT_CHAIN_ID
  const outputChain = ChainId[Number(parsedQs.outputChain)] ? Number(parsedQs.outputChain) : DEFAULT_OUTPUT_CHAIN_ID

  const inputSymbol = Native.onChain(inputChain).symbol
  const outputSymbol = Native.onChain(outputChain).symbol

  let inputCurrency =
    typeof parsedQs.inputCurrency === 'string'
      ? safeGetAddress(parsedQs.inputCurrency) || inputSymbol
      : inputSymbol ?? DEFAULT_INPUT_CURRENCY

  let outputCurrency =
    typeof parsedQs.outputCurrency === 'string'
      ? safeGetAddress(parsedQs.outputCurrency) || outputSymbol
      : outputSymbol ?? DEFAULT_OUTPUT_CURRENCY

  if (inputCurrency === outputCurrency) {
    if (typeof parsedQs.outputCurrency === 'string') {
      inputCurrency = ''
    } else {
      outputCurrency = ''
    }
  }

  const recipient = validatedRecipient(parsedQs.recipient)

  return {
    [Field.INPUT]: {
      currencyId: inputCurrency,
      chainId: inputChain,
    },
    [Field.OUTPUT]: {
      currencyId: outputCurrency,
      chainId: outputChain,
    },
    typedValue: parseTokenAmountURLParameter(parsedQs.exactAmount),
    independentField: parseIndependentFieldURLParameter(parsedQs.exactField),
    recipient,
  }
}

// updates the swap state to use the defaults for a given network
export function useDefaultsCrossSwapFromURLSearch():
  | {
      inputCurrencyId: string | undefined
      outputCurrencyId: string | undefined
      inputChain: ChainId | undefined
      outputChain: ChainId | undefined
    }
  | undefined {
  const { chainId } = useActiveChainId()
  const [, dispatch] = useAtom(crossSwapReducerAtom)
  const { query, isReady } = useRouter()

  const [result, setResult] = useState<
    | {
        inputCurrencyId: string | undefined
        outputCurrencyId: string | undefined
        inputChain: ChainId | undefined
        outputChain: ChainId | undefined
      }
    | undefined
  >()

  useEffect(() => {
    if (!isReady || result?.inputCurrencyId) return
    const parsed = queryParametersToCrossSwapState(query, chainId)

    dispatch(
      replaceCrossSwapState({
        typedValue: parsed.typedValue,
        field: parsed.independentField,
        inputChainId: parsed[Field.INPUT].chainId,
        inputCurrencyId: parsed[Field.INPUT].currencyId,
        outputChainId: parsed[Field.OUTPUT].chainId,
        outputCurrencyId: parsed[Field.OUTPUT].currencyId,
        recipient: null,
      }),
    )
    setResult({
      inputCurrencyId: parsed[Field.INPUT].currencyId,
      inputChain: parsed[Field.INPUT].chainId,
      outputChain: parsed[Field.OUTPUT].chainId,
      outputCurrencyId: parsed[Field.OUTPUT].currencyId,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isReady])

  return result
}

export function useCrossSwapActionHandlers(): {
  onCurrencySelection: (field: Field, chainId: ChainId, currency: Currency) => void
  onSwitchTokens: () => void
  onUserInput: (field: Field, typedValue: string) => void
  onChangeRecipient: (recipient: string | null) => void
} {
  const [, dispatch] = useAtom(crossSwapReducerAtom)

  const onSwitchTokens = useCallback(() => {
    dispatch(switchCurrencies())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onCurrencySelection = useCallback((field: Field, chainId: ChainId, currency: Currency) => {
    dispatch(
      selectCurrency({
        field,
        currencyId: currency?.isToken ? currency.address : currency?.isNative ? currency.symbol : '',
        chainId,
      }),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onUserInput = useCallback((field: Field, typedValue: string) => {
    dispatch(typeInput({ field, typedValue }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onChangeRecipient = useCallback((recipient: string | null) => {
    dispatch(setRecipient({ recipient }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    onSwitchTokens,
    onCurrencySelection,
    onUserInput,
    onChangeRecipient,
  }
}

export function useFeesAmount(selectedChainId?: ChainId): (CurrencyAmount<Currency> | undefined)[] {
  const currencies = useMemo(() => {
    if (!selectedChainId) return []

    const native = SUPPORT_CROSS_CHAIN.map((chain) => Native.onChain(chain)).filter(
      (currency) => currency && !isChainZeta(currency.chainId),
    )
    return [...native].map((item) => {
      if (isChainBitcoin(item.chainId)) {
        return getZrc20ByCurrency(item)
      }
      return item
    })
  }, [selectedChainId])

  const calls = useMemo(() => {
    return currencies.map((currency) => {
      const zrc20 = getZrc20ByCurrency(currency)?.address
      if (!currency || !zrc20) {
        return {
          abi: [],
          address: zeroAddress,
          args: [],
          chainId: 0,
          functionName: '',
        }
      }

      return {
        abi: zrc20ABI,
        address: zrc20,
        args: [],
        chainId: getZetaChainByAnotherChain(currency.chainId),
        functionName: 'withdrawGasFee',
      }
    })
  }, [currencies])

  // @ts-ignore
  const { data } = useReadContracts({
    // @ts-ignore
    contracts: calls,
    allowFailure: false,
    config,
    query: {
      refetchInterval: SLOW_INTERVAL,
      cacheTime: SLOW_INTERVAL,
    },
  })

  return useMemo(
    () =>
      currencies?.map((currency, index) => {
        if (!currency && !data?.[index]?.[1]) return undefined
        if (isBTC(currency, currency.chainId)) {
          return (
            data &&
            CurrencyAmount.fromRawAmount(
              Native.onChain(getBTCChainByAnotherChain(currency.chainId)),
              data?.[index][1].toString(),
            )
          )
        }
        return data && CurrencyAmount.fromRawAmount(currency, data?.[index][1].toString())
      }) ?? [],
    [currencies, data],
  )
}

export const useFeeCrossSwap = (trade: CrossSwapTrade): number | undefined => {
  const fees = useFeesAmount(trade.methodSwap ? trade.inputCurrencyAmount?.currency.chainId : undefined)

  const currencyFees = useMemo(() => {
    if (!trade.methodSwap || !trade?.outputCurrencyAmount?.currency) return undefined

    return fees.find(
      (fee) => fee?.currency.isNative && fee.currency.chainId === trade.outputCurrencyAmount?.currency.chainId,
    )
  }, [fees, trade])

  const currency = useMemo(() => {
    if (!currencyFees) return undefined
    if (isBTC(currencyFees.currency, currencyFees.currency.chainId)) {
      return getZrc20ByCurrency(currencyFees.currency)
    }
    return currencyFees.currency
  }, [currencyFees])

  const { price } = useLlamaCurrencyPrice(currency, {
    chainId: currency?.chainId,
    enabled: Boolean(currency?.chainId && trade.methodSwap),
  })

  return useMemo(() => {
    if (!price) return undefined
    return multiplyPriceByAmount(price, Number(currencyFees?.toExact()))
  }, [currencyFees, price])
}

interface BuildParameters {
  abi: any
  functionName: string

  from: string
  to: string

  params: any[]
  data: string
  value: string

  trade: CrossSwapTrade | undefined
  isBitcoin?: boolean
}

const defaultParams: BuildParameters = {
  abi: [],
  functionName: '',

  from: '',
  to: '',

  params: [],
  data: '',
  value: '0x0',

  trade: undefined,
}

export const getCrossSwapParamaters = ({
  trade,
  evmAccount,
  bitcoinAccount,
}: {
  trade: CrossSwapTrade
  evmAccount: Address
  bitcoinAccount: string
}) => {
  const encodeData = (abi: any, functionName: string, params) => {
    const iface = new ethers.utils.Interface(abi)
    const data = iface.encodeFunctionData(functionName, params)
    return data
  }

  const crossChainSwapBTCHandle = (
    action: (typeof ActionCrossChainSwapBTC)[keyof typeof ActionCrossChainSwapBTC],
  ): BuildParameters => {
    const zrc20 = trade.outputCurrencyAmount?.currency && getZrc20ByCurrency(trade.outputCurrencyAmount?.currency)
    if (!bitcoinAccount || !zrc20?.address || !trade?.inputCurrencyAmount?.currency) {
      return defaultParams
    }

    const contract = clear0xAddress(getOmniChainAddress(trade.inputCurrencyAmount.currency.chainId))
    const zrc20Address = clear0xAddress(zrc20.address)
    const recipient = clear0xAddress(trade.recipient as any)
    const memo = `hex::${contract}${action}${zrc20Address}${recipient}`
    const amount = trade.inputCurrencyAmount.quotient.toString()

    const to = getTSSAddress(trade.inputCurrencyAmount.currency.chainId)

    return {
      functionName: 'transfer',
      abi: [],

      from: bitcoinAccount,
      to,
      data: '',
      value: '0x0',

      params: [
        bitcoinAccount, // from
        to, // recipient
        FEE_RATE, // feeRate
        memo, // memo
        amount, // amount
        trade.inputCurrencyAmount.currency.decimals, // decimals
      ],
      isBitcoin: true,
      trade,
    }
  }

  const depositERC20 = (): BuildParameters => {
    try {
      const inputChain = trade.inputCurrencyAmount?.currency.chainId

      if (!trade || !trade.inputCurrencyAmount || !inputChain) return defaultParams

      const params = [
        trade.recipient,
        trade.inputCurrencyAmount.currency.wrapped.address,
        trade.inputCurrencyAmount.quotient.toString(),
        '0x',
      ]

      return {
        abi: erc20CustodyABI,
        functionName: 'deposit',

        from: evmAccount,
        to: getErc20CustodyAddress(inputChain),

        data: encodeData(erc20CustodyABI, 'deposit', params),
        params,
        value: '0x0',

        trade,
      }
    } catch (error) {
      return defaultParams
    }
  }

  const crossChainSwapHandle = (
    action: (typeof ActionCrossChainSwap)[keyof typeof ActionCrossChainSwap],
  ): BuildParameters => {
    try {
      const inputChain = trade.inputCurrencyAmount?.currency.chainId
      const outputChain = trade.outputCurrencyAmount?.currency.chainId

      if (!trade.inputCurrencyAmount || !trade.outputCurrencyAmount || !outputChain) {
        return defaultParams
      }

      const recipient = isChainBitcoin(outputChain) ? getBytesAddress(trade.recipient) : trade.recipient

      const omnichainAddress = getOmniChainAddress(inputChain)
      const to = getTSSAddress(inputChain)
      const address = getZrc20ByCurrency(trade.outputCurrencyAmount.currency)?.address

      const data = prepareData(omnichainAddress, ['uint8', 'address', 'bytes'], [action, address, recipient]) as Address

      return {
        functionName: '',
        abi: [],

        from: evmAccount,
        to,

        data,
        value: trade.inputCurrencyAmount.quotient.toString(),
        params: [],

        trade,
      }
    } catch (error) {
      return defaultParams
    }
  }

  const crossChainSwapNative = (): BuildParameters => {
    try {
      const inputChain = trade.inputCurrencyAmount?.currency.chainId
      const outputChain = trade.outputCurrencyAmount?.currency.chainId
      const zrc20 = trade?.outputCurrencyAmount?.currency && getZrc20ByCurrency(trade?.outputCurrencyAmount?.currency)

      if (!trade.inputCurrencyAmount?.currency || !trade.outputCurrencyAmount?.currency || !outputChain || !zrc20) {
        return defaultParams
      }

      const recipient = isChainBitcoin(outputChain) ? getBytesAddress(trade.recipient) : trade.recipient
      const params = [
        trade.inputCurrencyAmount.currency.wrapped.address,
        zrc20.address as Address,
        recipient as Address,
      ]

      return {
        abi: omniABI,
        functionName: 'withdrawNative',

        from: evmAccount,
        to: getOmniChainAddress(inputChain),
        data: encodeData(omniABI, 'withdrawNative', params),
        params,
        value: trade.inputCurrencyAmount.quotient.toString(),

        trade,
      }
    } catch (error) {
      return defaultParams
    }
  }

  const crossChainZeta = (): BuildParameters => {
    try {
      const inputChain = trade.inputCurrencyAmount?.currency.chainId
      const outputChain = trade.outputCurrencyAmount?.currency.chainId
      const zrc20 = trade?.outputCurrencyAmount?.currency && getZrc20ByCurrency(trade?.outputCurrencyAmount?.currency)

      if (!trade.inputCurrencyAmount?.currency || !trade.outputCurrencyAmount?.currency || !outputChain || !zrc20) {
        return defaultParams
      }

      const params = [
        trade.inputCurrencyAmount.currency.wrapped.address,
        zrc20.address,
        isChainBitcoin(trade.outputCurrencyAmount.currency.chainId)
          ? getBytesAddress(trade.recipient)
          : trade.recipient,
        trade.inputCurrencyAmount.quotient.toString(),
      ]

      return {
        abi: omniABI,
        functionName: 'withdraw',

        from: evmAccount,
        to: getOmniChainAddress(inputChain),
        data: encodeData(omniABI, 'withdraw', params),
        value: '0x0',
        params,
        trade,
      }
    } catch (error) {
      console.error(error)
      return defaultParams
    }
  }

  const crossChainSwap = (): BuildParameters => crossChainSwapHandle(ActionCrossChainSwap.crossChainSwap)
  const crossChainSwapTransfer = (): BuildParameters =>
    crossChainSwapHandle(ActionCrossChainSwap.crossChainSwapTransfer)
  const crossChainSwapNativeTransfer = (): BuildParameters =>
    crossChainSwapHandle(ActionCrossChainSwap.crossChainSwapNativeTransfer)

  const crossChainSwapBTC = (): BuildParameters => crossChainSwapBTCHandle(ActionCrossChainSwapBTC.crossChainSwapBTC)
  const crossChainSwapBTCTransfer = (): BuildParameters =>
    crossChainSwapBTCHandle(ActionCrossChainSwapBTC.crossChainSwapBTCTransfer)
  const crossChainSwapBTCTransferNative = (): BuildParameters =>
    crossChainSwapBTCHandle(ActionCrossChainSwapBTC.crossChainSwapBTCTransferNative)

  const paramatersByMethod = {
    depositERC20,
    crossChainZeta,
    crossChainSwap,
    crossChainSwapTransfer,
    crossChainSwapNativeTransfer,

    crossChainSwapBTC,
    crossChainSwapBTCTransfer,
    crossChainSwapBTCTransferNative,
    crossChainSwapNative,
  }

  return (trade.methodSwap && paramatersByMethod?.[trade.methodSwap]?.()) || defaultParams
}

export const useCrossSwapCallParameters = ({
  trade,
  evmAccount,
  bitcoinAccount,
}: {
  trade: CrossSwapTrade
  evmAccount: Address
  bitcoinAccount: string
}) => {
  return useMemo(() => {
    if (!trade || !trade?.methodSwap) return defaultParams
    const handleBuildParameters = getCrossSwapParamaters({
      trade,
      evmAccount,
      bitcoinAccount,
    })
    return handleBuildParameters
  }, [bitcoinAccount, evmAccount, trade])
}

export const useHandleCrossSwap = ({
  trade,
  evmAccount,
  bitcoinAccount,
}: {
  trade: CrossSwapTrade
  evmAccount: Address
  bitcoinAccount: string
}) => {
  const addTransaction = useAddTransactionCrossSwap()
  const provider = useWalletBitcoinProviderByWallet()

  const getKey = (token: CurrencyAmount<Currency>) => {
    return token?.currency.isNative ? token?.currency.symbol : token?.currency.wrapped.address
  }

  const parameters = useCrossSwapCallParameters({
    trade,
    evmAccount,
    bitcoinAccount,
  })
  const fallbackCrossSwap = useCallback((error: unknown) => {
    console.error(error)
    return null
  }, [])

  const addCustomTransaction = useCallback(
    (hash: Address, method: MethodCrossSwap) => {
      if (trade.inputCurrencyAmount && trade.outputCurrencyAmount) {
        addTransaction({
          method,
          hash,

          inputChain: trade.inputCurrencyAmount?.currency.chainId,
          outputChain: trade.outputCurrencyAmount?.currency.chainId,
          inputCurrencyId: getKey(trade.inputCurrencyAmount),
          outputCurrencyId: getKey(trade.outputCurrencyAmount),
          amountIn: trade.inputCurrencyAmount.quotient.toString(),
          amountOut: trade.outputCurrencyAmount.quotient.toString(),
          createTime: Date.now(),
          status: CrossChainStatus.Inbound,
        })
        // onPresentOpenHistoriesModal()
      }
    },
    [addTransaction, trade],
  )

  return useCallback(() => {
    if (!trade || !trade.methodSwap) return null

    if (parameters.isBitcoin && parameters.params.length > 0) {
      const { params } = parameters

      if (!provider) return null

      return provider
        .transfer({
          from: params[0],
          recipient: params[1],
          feeRate: params[2],
          memo: params[3],
          amount: params[4],
          decimals: params[5],
        })
        .then((hash) => {
          addCustomTransaction(hash, trade.methodSwap as MethodCrossSwap)
          return hash
        })
        .catch(fallbackCrossSwap)
    }

    return sendTransaction(config, {
      to: parameters.to as Address,
      account: evmAccount,
      chainId: trade?.inputCurrencyAmount?.currency?.chainId,
      data: parameters.data as Address,
      value: toBigInt(parameters.value),
    })
      .then((hash) => {
        addCustomTransaction(hash, trade.methodSwap as MethodCrossSwap)
        return hash
      })
      .catch(fallbackCrossSwap)
  }, [addCustomTransaction, evmAccount, fallbackCrossSwap, parameters, provider, trade])
}

export const useEstimateSwapCrossChain = ({
  trade,
  evmAccount,
  bitcoinAccount,
}: {
  trade: CrossSwapTrade
  evmAccount: Address
  bitcoinAccount: string
}) => {
  const { connector } = useActiveWeb3React()

  const params = useCrossSwapCallParameters({
    trade,
    evmAccount,
    bitcoinAccount,
  })
  const { data: gas } = useEstimateGas({
    data: params.data as Address,
    chainId: trade.inputCurrencyAmount?.currency?.chainId,
    config,
    connector,
    to: params.to as Address,
    value: toBigInt(params.value),
    query: {
      enabled: !!params.data || !params?.isBitcoin,
    },
  })

  return useMemo(() => gas?.toString() || '', [gas])
}

// export const useHandleCrossChain = ({
//   trade,
//   evmAccount,
//   bitcoinAccount,
// }: {
//   trade: CrossSwapTrade
//   evmAccount: Address
//   bitcoinAccount: string
// }) => {
//   const provider = useWalletBitcoinProviderByWallet()
//   // const { onPresentOpenHistoriesModal } = useOpenModalHistories()
//   const addTransaction = useAddTransactionCrossSwap()

//   const refetchBalancesAfter = useCallback(() => {}, [])

//   const getKey = (token: CurrencyAmount<Currency>) => {
//     return token?.currency.isNative ? token?.currency.symbol : token?.currency.wrapped.address
//   }

//   const fallbackCrossSwap = useCallback(
//     (error: unknown) => {
//       refetchBalancesAfter()
//       console.error(error)
//     },
//     [refetchBalancesAfter],
//   )

//   const addCustomTransaction = useCallback(
//     (hash: Address, method: MethodCrossSwap) => {
//       if (trade.inputCurrencyAmount && trade.outputCurrencyAmount) {
//         addTransaction({
//           method,
//           hash,

//           inputChain: trade.inputCurrencyAmount?.currency.chainId,
//           outputChain: trade.outputCurrencyAmount?.currency.chainId,
//           inputCurrency: getKey(trade.inputCurrencyAmount),
//           outputCurency: getKey(trade.outputCurrencyAmount),
//           amountIn: trade.inputCurrencyAmount.quotient.toString(),
//           amountOut: trade.outputCurrencyAmount.quotient.toString(),
//           createTime: Date.now(),
//           status: CrossChainStatus.Inbound,
//         })
//         // onPresentOpenHistoriesModal()
//         refetchBalancesAfter()
//       }
//     },
//     [addTransaction, refetchBalancesAfter, trade],
//   )

//   const crossChainSwapBTCHandle = useCallback(
//     (action: (typeof ActionCrossChainSwapBTC)[keyof typeof ActionCrossChainSwapBTC]) => {
//       if (!bitcoinAccount) {
//         console.error('EVM address undefined.')
//         return
//       }

//       provider
//         .swapBTC({
//           from: bitcoinAccount,
//           action,
//           trade,
//         })
//         .then((hash) => {
//           addCustomTransaction(hash, MethodCrossSwap[action])
//         })
//         .catch(fallbackCrossSwap)
//     },
//     [bitcoinAccount, provider, trade, fallbackCrossSwap, addCustomTransaction],
//   )

//   // useEffect(() => {
//   //   if (account) {
//   //     addTransaction({
//   //       method: MethodCrossSwap.crossChainZeta,
//   //       hash: '0xf091931d64df31d58a34dca2de5304813496633e29f18e8868acd8be4ed1bf6d',
//   //       inputChain: ChainId.BSC_MAINNET,
//   //       outputChain: ChainId.MAINNET,
//   //       inputCurrency: serializeToken(
//   //         new Token(ChainId.BSC_MAINNET as any, '0x65a45c57636f9BcCeD4fe193A602008578BcA90b', 8, 'ZETA', 'XETA', ''),
//   //       ),
//   //       // outputCurency: {
//   //       //   name: Currency.ETHER.name,
//   //       //   symbol: Currency.ETHER.symbol,
//   //       //   decimals: Currency.ETHER.decimals,
//   //       // },
//   //       outputCurency: serializeToken(
//   //         new Token(ChainId.MAINNET as any, '0x65a45c57636f9BcCeD4fe193A602008578BcA90b', 8, 'tBTC', 'tBTC', ''),
//   //       ),
//   //       amountIn: '50000',
//   //       amountOut: '26598000000',
//   //       createTime: 1705347772732,
//   //       status: CrossChainStatus.Inbound,
//   //     })
//   //   }
//   // }, [account])

//   const depositERC20 = useCallback(async () => {
//     try {
//       const inputChain = trade?.inputCurrencyAmount?.currency?.chainId
//       const client = publicClient({
//         chainId: inputChain,
//       })

//       if (client && trade?.inputCurrencyAmount?.currency) {
//         const custodyContract = getContract({
//           abi: erc20CustodyABI,
//           address: getErc20CustodyAddress(inputChain),
//           client,
//         })

//         await custodyContract.write
//           .deposit([
//             trade.recipient,
//             trade.inputCurrencyAmount.currency.wrapped.address,
//             trade.inputCurrencyAmount.quotient.toString(),
//             '0x',
//           ])
//           .then((hash) => {
//             addCustomTransaction(hash, MethodCrossSwap.depositERC20)
//           })
//           .catch(fallbackCrossSwap)
//       }
//     } catch (error) {
//       fallbackCrossSwap(error)
//     }
//   }, [trade, fallbackCrossSwap, addCustomTransaction])

//   const crossChainSwapHandle = useCallback(
//     async (action: (typeof ActionCrossChainSwap)[keyof typeof ActionCrossChainSwap]) => {
//       try {
//         const inputChain = trade.inputCurrencyAmount?.currency.chainId
//         const outputChain = trade.outputCurrencyAmount?.currency.chainId
//         const client = publicClient({
//           chainId: inputChain,
//         })

//         if (trade.inputCurrencyAmount?.currency && trade.outputCurrencyAmount?.currency && outputChain && client) {
//           const recipient = isChainBitcoin(outputChain) ? getBytesAddress(trade.recipient) : trade.recipient
//           const omnichainAddress = getOmniChainAddress(inputChain)
//           const to = getTSSAddress(inputChain)
//           const zrc20 = getZrc20ByCurrency(trade.outputCurrencyAmount.currency)

//           const data = prepareData(
//             omnichainAddress,
//             ['uint8', 'address', 'bytes'],
//             [action, zrc20?.address, recipient],
//           ) as Address

//           await sendTransaction(config, {
//             to,
//             data,
//             account: evmAccount,
//             value: toBigInt(trade.inputCurrencyAmount.quotient.toString()),
//           })
//             .then((hash) => addCustomTransaction(hash, ActionCrossChainSwap[action]))
//             .catch(fallbackCrossSwap)
//         }
//       } catch (error) {
//         fallbackCrossSwap(error)
//       }
//     },
//     [trade, evmAccount, fallbackCrossSwap, addCustomTransaction],
//   )

//   const crossChainSwapNative = useCallback(async () => {
//     try {
//       const inputChain = trade.inputCurrencyAmount?.currency.chainId
//       const outputChain = trade.outputCurrencyAmount?.currency.chainId
//       const client = publicClient({
//         chainId: inputChain,
//       })
//       const zrc20 = trade?.outputCurrencyAmount?.currency && getZrc20ByCurrency(trade?.outputCurrencyAmount?.currency)

//       if (
//         trade.inputCurrencyAmount?.currency &&
//         trade.outputCurrencyAmount?.currency &&
//         outputChain &&
//         zrc20 &&
//         client
//       ) {
//         const recipient = isChainBitcoin(outputChain) ? getBytesAddress(trade.recipient) : trade.recipient
//         const contract = getContract({
//           abi: omniABI,
//           address: getOmniChainAddress(inputChain),
//           client,
//         })

//         contract.write
//           .withdrawNative(
//             [trade.inputCurrencyAmount.currency.wrapped.address, zrc20.address as Address, recipient as Address],
//             {
//               account: evmAccount,
//               value: toBigInt(trade.inputCurrencyAmount.quotient.toString()),
//             },
//           )
//           .then((hash) => {
//             addCustomTransaction(hash, MethodCrossSwap.crossChainSwapNative)
//           })
//           .catch(fallbackCrossSwap)
//       }
//     } catch (error) {
//       fallbackCrossSwap(error)
//     }
//   }, [trade, evmAccount, fallbackCrossSwap, addCustomTransaction])

//   const crossChainZeta = useCallback(async () => {
//     try {
//       const inputChain = trade.inputCurrencyAmount?.currency.chainId
//       const outputChain = trade.outputCurrencyAmount?.currency.chainId
//       const client = publicClient({
//         chainId: inputChain,
//       })
//       const zrc20 = trade?.outputCurrencyAmount?.currency && getZrc20ByCurrency(trade?.outputCurrencyAmount?.currency)
//       if (
//         trade.inputCurrencyAmount?.currency &&
//         trade.outputCurrencyAmount?.currency &&
//         outputChain &&
//         zrc20 &&
//         client
//       ) {
//         const contract = getContract({
//           abi: omniABI,
//           address: getOmniChainAddress(inputChain),
//           client,
//         })

//         await contract.write
//           .withdraw(
//             [
//               trade.inputCurrencyAmount.currency.wrapped.address,
//               zrc20.address,
//               trade.recipient,
//               toBigInt(trade.inputCurrencyAmount.quotient.toString()),
//             ],
//             {
//               account: evmAccount,
//             },
//           )
//           .then((hash) => {
//             addCustomTransaction(hash, MethodCrossSwap.crossChainZeta)
//           })
//           .catch(fallbackCrossSwap)
//       }
//     } catch (error) {
//       fallbackCrossSwap(error)
//     }
//   }, [trade, evmAccount, addCustomTransaction, fallbackCrossSwap])

//   const crossChainSwap = useCallback(
//     () => crossChainSwapHandle(ActionCrossChainSwap.crossChainSwap),
//     [crossChainSwapHandle],
//   )
//   const crossChainSwapTransfer = useCallback(
//     () => crossChainSwapHandle(ActionCrossChainSwap.crossChainSwapTransfer),
//     [crossChainSwapHandle],
//   )
//   const crossChainSwapNativeTransfer = useCallback(
//     () => crossChainSwapHandle(ActionCrossChainSwap.crossChainSwapNativeTransfer),
//     [crossChainSwapHandle],
//   )

//   const crossChainSwapBTC = useCallback(
//     () => crossChainSwapBTCHandle(ActionCrossChainSwapBTC.crossChainSwapBTC),
//     [crossChainSwapBTCHandle],
//   )
//   const crossChainSwapBTCTransfer = useCallback(
//     () => crossChainSwapBTCHandle(ActionCrossChainSwapBTC.crossChainSwapBTCTransfer),
//     [crossChainSwapBTCHandle],
//   )
//   const crossChainSwapBTCTransferNative = useCallback(
//     () => crossChainSwapBTCHandle(ActionCrossChainSwapBTC.crossChainSwapBTCTransferNative),
//     [crossChainSwapBTCHandle],
//   )

//   return useMemo(
//     () => ({
//       depositERC20,

//       crossChainZeta,

//       crossChainSwap,
//       crossChainSwapTransfer,
//       crossChainSwapNativeTransfer,

//       crossChainSwapBTC,
//       crossChainSwapBTCTransfer,
//       crossChainSwapBTCTransferNative,

//       crossChainSwapNative,
//     }),
//     [
//       depositERC20,
//       crossChainZeta,
//       crossChainSwap,
//       crossChainSwapTransfer,
//       crossChainSwapNativeTransfer,
//       crossChainSwapBTC,
//       crossChainSwapBTCTransfer,
//       crossChainSwapBTCTransferNative,
//       crossChainSwapNative,
//     ],
//   )
// }
