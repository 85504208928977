import { Button, MotionBox } from '@pancakeswap/uikit'
import { AppBody } from 'components/App'
import { styled } from 'styled-components'

export const StyledInputCurrencyWrapper = styled(AppBody)`
  width: 100%;
  max-width: 460px;

  overflow: unset;

  ${({ theme }) => theme.mediaQueries.sm} {
    width: 460px;
  }
`

export const IconWrapper = styled(MotionBox)<{ width: string; height: string; bg: string }>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background: ${({ theme, bg }) => theme.colors[bg]};
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const XdefiConnectButton = styled(Button)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  max-height: 53px;
  width: 100%;
  max-width: 400px;

  background: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.text};
  font-weight: 500;
  padding: 8px 12px;
`
