import { Button, ButtonProps, useModalV2 } from '@pancakeswap/uikit'
// @ts-ignore
// eslint-disable-next-line import/extensions
import ConnectBitcoinWalletModal from './ConnectBitcoinWalletModal'
import Trans from './Trans'

const ConnectBitcoinWalletButton = ({ children, ...props }: ButtonProps) => {
  const bitcoinConnectModal = useModalV2()

  const handleClick = () => {
    bitcoinConnectModal.onOpen()
  }

  return (
    <>
      <Button onClick={handleClick} {...props}>
        {children || <Trans>Connect Bitcoin Wallet</Trans>}
      </Button>
      <ConnectBitcoinWalletModal {...bitcoinConnectModal} />
    </>
  )
}

export default ConnectBitcoinWalletButton
