import { useTranslation } from '@pancakeswap/localization'
import { Flex } from '@pancakeswap/uikit'
import { Swap } from '@pancakeswap/widgets-internal'
import { FC } from 'react'

export const FormHeader: FC = () => {
  const { t } = useTranslation()

  const titleContent = (
    <Flex width="100%" alignItems="center" justifyContent="space-between" flexDirection="column">
      <Flex width="100%" justifyContent="space-between" alignItems={['center']} marginBottom={2}>
        <Flex width="max-content">
          <Swap.CurrencyInputHeaderTitle>{t('Cross Swap')}</Swap.CurrencyInputHeaderTitle>
        </Flex>
      </Flex>

      <Flex justifyContent="start" width="100%" height="17px" alignItems="center">
        <Swap.CurrencyInputHeaderSubTitle>{t('Trade tokens in an instant')}</Swap.CurrencyInputHeaderSubTitle>
      </Flex>
    </Flex>
  )

  return <Swap.CurrencyInputHeader title={titleContent} subtitle={<></>} />
}
