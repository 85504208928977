import { AutoColumn } from '@pancakeswap/uikit'
import { Swap as SwapUI } from '@pancakeswap/widgets-internal'

import replaceBrowserHistory from '@pancakeswap/utils/replaceBrowserHistory'
import { memo, useCallback } from 'react'

import { AutoRow } from 'components/Layout/Row'
import { useCrossSwapActionHandlers } from 'state/crossSwap/hooks'
import { Field } from 'state/swap/actions'
import { useSwapState } from 'state/swap/hooks'

export const FlipButton = memo(function FlipButton() {
  const { onSwitchTokens } = useCrossSwapActionHandlers()
  const {
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
  } = useSwapState()

  const onFlip = useCallback(() => {
    onSwitchTokens()
    replaceBrowserHistory('inputCurrency', outputCurrencyId)
    replaceBrowserHistory('outputCurrency', inputCurrencyId)
  }, [onSwitchTokens, inputCurrencyId, outputCurrencyId])

  return (
    <AutoColumn justify="space-between">
      <AutoRow justify="center" style={{ padding: '0 1rem', margin: '1em 0' }}>
        <SwapUI.SwitchButton onClick={onFlip} />
      </AutoRow>
    </AutoColumn>
  )
})
