import {
  Button,
  CheckmarkCircleFillIcon,
  FlexGap,
  Modal,
  ModalBody,
  ModalHeader,
  ModalV2,
  ModalV2Props,
  OpenNewIcon,
  Text,
} from '@pancakeswap/uikit'

const ConfirmedHistoryModal: React.FC<ModalV2Props & { onCallback: () => void }> = ({
  onDismiss,
  onCallback,
  ...props
}) => {
  return (
    <ModalV2 onDismiss={onDismiss} closeOnOverlayClick {...props}>
      <Modal
        p="0"
        title=""
        headerPadding="0 !important"
        hideCloseButton
        maxWidth={['100%', '', '', 450]}
        minWidth={['100%', '', '', '450px']}
        minHeight={200}
        headerBorderColor="transparent"
        headerAlignItems="center"
      >
        <ModalHeader px="0 !important" justifyContent="center" headerBorderColor="transparent">
          <Text as="h2" fontSize={['24px', '', '32px']} bold>
            Confirm Cross-swap
          </Text>
        </ModalHeader>
        <ModalBody mt="24px">
          <FlexGap flexDirection="column" alignItems="center" justifyContent="center">
            <CheckmarkCircleFillIcon color="primary" width="100px" height="100px" />
            <Text my="24px" color="absBlackColor" fontSize="18px" fontWeight="700">
              Transaction Sent
            </Text>

            <Button
              mb="12px"
              maxWidth="260px"
              width="100%"
              onClick={() => {
                if (onCallback) onCallback()
                if (onDismiss) onDismiss()
              }}
              style={{
                fontSize: '16px',
                fontWeight: '500',
                justifyContent: 'center',
                backgroundColor: 'rgb(50,52,54)',
              }}
            >
              <Text small color="text80">
                View Cross-swap history
              </Text>
              <OpenNewIcon color="white" ml="4px" />
            </Button>

            <Button maxWidth="220px" width="100%" onClick={onDismiss}>
              Close
            </Button>
          </FlexGap>
        </ModalBody>
      </Modal>
    </ModalV2>
  )
}

export default ConfirmedHistoryModal
