import { useTranslation } from '@pancakeswap/localization'
import { ChainId, Currency, CurrencyAmount, Pair, Percent } from '@pancakeswap/sdk'
import {
  Box,
  Button,
  ChevronDownIcon,
  Column,
  Flex,
  Loading,
  RowBetween,
  RowFixed,
  Skeleton,
  Text,
  useModal,
} from '@pancakeswap/uikit'
import { formatAmount } from '@pancakeswap/utils/formatFractions'
import { DoubleCurrencyLogo, Swap as SwapUI } from '@pancakeswap/widgets-internal'
import { memo, useCallback, useMemo } from 'react'
import { styled } from 'styled-components'

import { useStablecoinPriceAmount } from 'hooks/useBUSDPrice'
import { StablePair } from 'views/AddLiquidity/AddStableLiquidity/hooks/useStableLPDerivedMintInfo'

import { isChainBitcoin } from '@pancakeswap/chains'
import { formatNumber } from '@pancakeswap/utils/formatBalance'
import CurrencyLogo, { FiatLogo } from 'components/Logo/CurrencyLogo'
import ChainSelect from 'components/NetworkModal/ChainSelect'
import CurrencyModalView from 'components/SearchModal/types'
import { useBitcoinAccount } from 'contexts/ZetaContext/hook'
import { useAllTokenByChain } from 'hooks/Tokens'
import { getZrc20ByCurrency, isBTC } from 'state/crossSwap/types'
import { useCurrencyCrossChainBalance } from 'state/wallet/hooks'
import { useAccount } from 'wagmi'
import CurrencySearchModal from '../SearchModal/CurrencySearchModal'

const CurrencySelectButton = styled(Button).attrs({ variant: 'text', scale: 'sm' })`
  padding: 4px 4px 4px 14px;
  border: 1px solid ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.radii.default};
`

interface CurrencyInputPanelProps {
  value: string | undefined
  onUserInput: (value: string) => void
  onInputBlur?: () => void
  onPercentInput?: (percent: number) => void
  onMax?: () => void
  showQuickInputButton?: boolean
  showMaxButton: boolean
  maxAmount?: CurrencyAmount<Currency>
  lpPercent?: string
  label?: string
  onCurrencySelect?: (currency: Currency) => void
  onChainSelect?: (chainId: ChainId) => void
  currency?: Currency | null
  selectedChainId: ChainId
  disableCurrencySelect?: boolean
  hideBalance?: boolean
  pair?: Pair | StablePair | null
  otherCurrency?: Currency | null
  id: string
  beforeButton?: React.ReactNode
  disabled?: boolean
  error?: boolean | string
  showUSDPrice?: boolean
  currencyLoading?: boolean
  inputLoading?: boolean
  title?: React.ReactNode
  hideBalanceComp?: boolean
}

const CurrencyChainInputPanel = memo(function CurrencyChainInputPanel({
  value,
  onUserInput,
  onInputBlur,
  onPercentInput,
  onMax,
  showQuickInputButton = false,
  showMaxButton,
  maxAmount,
  lpPercent,
  label,
  onCurrencySelect,
  onChainSelect,
  currency,
  selectedChainId,
  disableCurrencySelect = false,
  hideBalance = false,
  beforeButton,
  pair = null, // used for double token logo
  otherCurrency,
  id,
  disabled = false,
  error,
  showUSDPrice,
  currencyLoading,
  inputLoading,
  title,
  hideBalanceComp,
}: CurrencyInputPanelProps) {
  const { address: account } = useAccount()
  const bitcoinAccount = useBitcoinAccount()

  const selectedCurrencyBalance = useCurrencyCrossChainBalance(
    account ?? undefined,
    bitcoinAccount ?? undefined,
    currency ?? undefined,
  )

  const { t } = useTranslation()

  const mode = id

  const currencyLLama = useMemo(() => {
    if (!currency) return undefined
    if (isBTC(currency, currency.chainId)) return getZrc20ByCurrency(currency)
    return currency
  }, [currency])

  const amountInDollar = useStablecoinPriceAmount(
    showUSDPrice && currencyLLama && !isChainBitcoin(currencyLLama?.chainId) ? currencyLLama ?? undefined : undefined,
    value !== undefined && Number.isFinite(+value) ? +value : undefined,
    {
      hideIfPriceImpactTooHigh: true,
      chainId: currencyLLama?.chainId,
      enabled: Boolean(currencyLLama && currencyLLama?.chainId && value !== undefined && Number.isFinite(+value)),
    },
  )

  const tokens = useAllTokenByChain(selectedChainId)

  const [onPresentCurrencyModal] = useModal(
    <CurrencySearchModal
      onCurrencySelect={onCurrencySelect}
      selectedCurrency={currency}
      selectedChainId={selectedChainId}
      otherSelectedCurrency={otherCurrency}
      showCommonBases={false}
      commonBasesType={undefined}
      showSearchInput={false}
      tokensToShow={tokens}
      mode={mode}
      view={CurrencyModalView.crossChain}
    />,
  )

  const percentAmount = useMemo(
    () => ({
      25: maxAmount ? maxAmount.multiply(new Percent(25, 100)).toExact() : undefined,
      50: maxAmount ? maxAmount.multiply(new Percent(50, 100)).toExact() : undefined,
      75: maxAmount ? maxAmount.multiply(new Percent(75, 100)).toExact() : undefined,
    }),
    [maxAmount],
  )

  const handleUserInput = useCallback(
    (val: string) => {
      onUserInput(val)
    },
    [onUserInput],
  )

  const onCurrencySelectClick = useCallback(() => {
    if (!disableCurrencySelect && selectedChainId) {
      onPresentCurrencyModal()
    }
  }, [onPresentCurrencyModal, disableCurrencySelect, selectedChainId])

  const isAtPercentMax = (maxAmount && value === maxAmount.toExact()) || (lpPercent && lpPercent === '100')

  const balance = !hideBalance && !!currency ? formatAmount(selectedCurrencyBalance, 6) : undefined

  return (
    <SwapUI.CurrencyChainInputPanel
      id={id}
      disabled={disabled}
      error={error as boolean}
      value={value}
      onInputBlur={onInputBlur}
      onUserInput={handleUserInput}
      loading={inputLoading}
      top={
        <RowBetween mb="4px">
          <Text color="black" fontSize="20px" fontWeight={600}>
            {title}
          </Text>
          <Flex alignItems="center">
            {beforeButton}
            <ChainSelect
              pl="4px"
              selectedChainId={selectedChainId}
              onSelectChainId={onChainSelect}
              dropdownContainer={{ minWidth: `210px !important` }}
            />
          </Flex>
        </RowBetween>
      }
      middle={
        <RowBetween>
          <RowFixed>
            <Text color="text80" fontSize="12px">
              Amount
            </Text>
            {account && currency && selectedCurrencyBalance?.greaterThan(0) && !disabled && label !== 'To' && (
              <RowFixed ml="8px" selected={disableCurrencySelect}>
                <Flex alignItems="right" justifyContent="right">
                  {maxAmount?.greaterThan(0) &&
                    showQuickInputButton &&
                    onPercentInput &&
                    [25, 50, 75].map((percent) => {
                      const isAtCurrentPercent =
                        (maxAmount && value !== '0' && value === percentAmount[percent]) ||
                        (lpPercent && lpPercent === percent.toString())

                      return (
                        <Button
                          key={`btn_quickCurrency${percent}`}
                          onClick={() => {
                            onPercentInput(percent)
                          }}
                          scale="xs"
                          maxWidth="36px"
                          borderRadius="4px"
                          mr="5px"
                          variant={isAtCurrentPercent ? 'primary' : 'secondary'}
                          style={{ textTransform: 'uppercase', fontSize: '10px' }}
                        >
                          {percent}%
                        </Button>
                      )
                    })}
                  {maxAmount?.greaterThan(0) && showMaxButton && (
                    <Button
                      onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        onMax?.()
                      }}
                      scale="xs"
                      maxWidth="36px"
                      borderRadius="4px"
                      variant={isAtPercentMax ? 'primary' : 'secondary'}
                      style={{ textTransform: 'uppercase', fontSize: '10px' }}
                    >
                      {t('Max')}
                    </Button>
                  )}
                </Flex>
              </RowFixed>
            )}
          </RowFixed>
          {account && !hideBalanceComp && (
            <Text
              pl="16px"
              onClick={!disabled ? onMax : undefined}
              color="textSubtle"
              fontSize="12px"
              ellipsis
              title={!hideBalance && !!currency ? t('Balance: %balance%', { balance: balance ?? t('Loading') }) : ' -'}
              style={{ display: 'inline', cursor: 'pointer' }}
            >
              {!hideBalance && !!currency
                ? (balance?.replace('.', '')?.length || 0) > 12
                  ? balance
                  : t('Balance: %balance%', { balance: balance ?? t('Loading') })
                : ' -'}
            </Text>
          )}
        </RowBetween>
      }
      rightInput={
        <>
          <CurrencySelectButton
            className="open-currency-select-button"
            selected={!!currency}
            disabled={!selectedChainId}
            onClick={onCurrencySelectClick}
          >
            <Flex alignItems="center" justifyContent="space-between">
              {pair ? (
                <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={16} margin />
              ) : currency ? (
                id === 'onramp-input' ? (
                  <FiatLogo currency={currency} size="20px" style={{ marginRight: '4px' }} />
                ) : (
                  <CurrencyLogo currency={currency} size="20px" style={{ marginRight: '4px' }} />
                )
              ) : currencyLoading ? (
                <Skeleton width="20px" height="20px" variant="circle" />
              ) : null}
              {currencyLoading ? null : pair ? (
                <Text color="text" id="pair" bold>
                  {pair?.token0.symbol}:{pair?.token1.symbol}
                </Text>
              ) : (
                <Text color="text" id="pair" bold>
                  {(currency && currency.symbol && currency.symbol.length > 10
                    ? `${currency.symbol.slice(0, 4)}...${currency.symbol.slice(
                        currency.symbol.length - 5,
                        currency.symbol.length,
                      )}`
                    : currency?.symbol) || t('Select a currency')}
                </Text>
              )}
              {!currencyLoading && !disableCurrencySelect && <ChevronDownIcon />}
            </Flex>
          </CurrencySelectButton>
        </>
      }
      bottom={
        <>
          <Column>
            {!!showUSDPrice && (
              <Flex justifyContent="flex-start" mt="-10px" mb="16px" ml="16px">
                <Flex maxWidth="200px">
                  {inputLoading ? (
                    <Loading width="14px" height="14px" />
                  ) : showUSDPrice && Number.isFinite(amountInDollar) ? (
                    <Text fontSize="12px" color="textSubtle" ellipsis>
                      {`~${amountInDollar ? formatNumber(amountInDollar) : 0} USD`}
                    </Text>
                  ) : (
                    <Box height="18px" />
                  )}
                </Flex>
              </Flex>
            )}
          </Column>
        </>
      }
    />
  )
})

export default CurrencyChainInputPanel
