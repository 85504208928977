import { Flex } from '@pancakeswap/uikit'

import { useDefaultsCrossSwapFromURLSearch } from 'state/crossSwap/hooks'
import Page from '../Page'
import CrossSwapForm from './components/CrossSwapForm'

import { StyledInputCurrencyWrapper } from './styles'

const CrossSwap = () => {
  useDefaultsCrossSwapFromURLSearch()

  return (
    <Page helpUrl="">
      <Flex width={['328px', '100%']} height="100%" justifyContent="center" position="relative" alignItems="flex-start">
        <StyledInputCurrencyWrapper>
          <CrossSwapForm />
        </StyledInputCurrencyWrapper>
      </Flex>
    </Page>
  )
}
export default CrossSwap
