import { ChainId } from '@pancakeswap/chains'
import { useTranslation } from '@pancakeswap/localization'
import { AutoRow, Box, Button, Column, MinusCircleFillIcon, PlusCircleFillIcon } from '@pancakeswap/uikit'
import { useState } from 'react'
import { useCrossSwapActionHandlers, useCrossSwapState } from 'state/crossSwap/hooks'
import { styled } from 'styled-components'
import AddressInputPanel from './AddressInputPanel'

const RecipientOptional: React.FC<{ chainId: ChainId }> = ({ chainId }) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  const { recipient } = useCrossSwapState()
  const { onChangeRecipient } = useCrossSwapActionHandlers()

  const handleSwitch = () => {
    if (open) {
      onChangeRecipient(null)
    }
    setOpen(!open)
  }

  return (
    <Column>
      <AutoRow>
        <Button
          style={{
            transform: 'unset !important',
          }}
          height="48px"
          px="0"
          variant="text"
          id="remove-recipient-button"
          onClick={handleSwitch}
        >
          {open ? (
            <>
              <MinusCircleFillIcon fill="black" mr="4px" width="20px" ml="2px" />
              {t('Remove send (optional)')}
            </>
          ) : (
            <>
              <PlusCircleFillIcon fill="black" mr="3px" width="23px" />
              {t('Add a send (optional)')}
            </>
          )}
        </Button>
      </AutoRow>
      <StyledWrapper $active={open}>
        <AddressInputPanel chainId={chainId} id="recipient" value={recipient || ''} onChange={onChangeRecipient} />
      </StyledWrapper>
    </Column>
  )
}

const StyledWrapper = styled(Box)<{ $active }>`
  height: auto;
  max-height: ${({ $active }) => ($active ? '400px' : '0')};
  overflow: hidden;
  transition: 0.3s ease-in-out;
`
export default RecipientOptional
