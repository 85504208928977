/* eslint-disable no-restricted-syntax */
import { useTranslation } from '@pancakeswap/localization'
import { ChainId, Currency, Token } from '@pancakeswap/sdk'
import { createFilterToken } from '@pancakeswap/token-lists'
import { Box, Column, Text, useMatchBreakpoints } from '@pancakeswap/uikit'
import { useAudioPlay } from '@pancakeswap/utils/user'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { useNativeCurrencyByChain } from 'hooks/useNativeCurrency'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { FixedSizeList } from 'react-window'
import { isAddress } from 'viem'
import { useAllTokenByChain } from '../../hooks/Tokens'
import CurrencyListCross from './CurrencyListCross'
import useTokenComparator from './sorting'
import { getSwapSound } from './swapSound'

interface CurrencySearchProps {
  selectedCurrency?: Currency | null
  selectedChainId: ChainId
  onCurrencySelect: (currency: Currency) => void
  otherSelectedCurrency?: Currency | null
  commonBasesType?: string
  showImportView: () => void
  setImportToken: (token: Token) => void
  height?: number
  tokensToShow?: Token[]
}

function CurrencyCross({
  selectedCurrency,
  selectedChainId,
  onCurrencySelect,
  otherSelectedCurrency,
  showImportView,
  setImportToken,
  height,
  tokensToShow,
}: CurrencySearchProps) {
  const { t } = useTranslation()
  const { chainId } = useActiveChainId()

  // refs for fixed size lists
  const fixedList = useRef<FixedSizeList>()

  const allTokens = useAllTokenByChain(selectedChainId)

  const { isMobile } = useMatchBreakpoints()
  const [audioPlay] = useAudioPlay()

  const native = useNativeCurrencyByChain(selectedChainId)

  const showNative: boolean = useMemo(() => {
    return !!native && !!selectedChainId
  }, [native, selectedChainId])

  const filteredTokens: Token[] = useMemo(() => {
    const filterToken = createFilterToken('', (address) => isAddress(address))
    return Object.values(tokensToShow || allTokens).filter(filterToken)
  }, [tokensToShow, allTokens])

  const filteredQueryTokens = useMemo(() => {
    if (!chainId) return filteredTokens
    return filteredTokens
  }, [filteredTokens, chainId])

  const tokenComparator = useTokenComparator(false)

  const filteredSortedTokens: Token[] = useMemo(() => {
    if (!selectedChainId) return []

    return [...filteredQueryTokens].sort(tokenComparator)
  }, [filteredQueryTokens, selectedChainId, tokenComparator])

  const handleCurrencySelect = useCallback(
    (currency: Currency) => {
      onCurrencySelect(currency)
      if (audioPlay) {
        getSwapSound().play()
      }
    },
    [audioPlay, onCurrencySelect],
  )

  // manage focus on modal show
  const inputRef = useRef<HTMLInputElement>()

  useEffect(() => {
    if (!isMobile) inputRef.current?.focus()
  }, [isMobile])

  const getCurrencyListRows = useCallback(() => {
    return filteredSortedTokens?.length || showNative ? (
      <Box mx="-24px">
        <CurrencyListCross
          height={isMobile ? (height ? height + 80 : 350) : 390}
          showNative={showNative}
          selectedChainId={selectedChainId}
          currencies={filteredSortedTokens}
          inactiveCurrencies={[]}
          breakIndex={undefined}
          onCurrencySelect={handleCurrencySelect}
          otherCurrency={otherSelectedCurrency}
          selectedCurrency={selectedCurrency}
          fixedListRef={fixedList}
          showImportView={showImportView}
          setImportToken={setImportToken}
        />
      </Box>
    ) : (
      <Column style={{ padding: '20px', height: '100%' }}>
        <Text color="textSubtle" textAlign="center" mb="20px">
          {t('No results found.')}
        </Text>
      </Column>
    )
  }, [
    filteredSortedTokens,
    isMobile,
    height,
    showNative,
    selectedChainId,
    handleCurrencySelect,
    otherSelectedCurrency,
    selectedCurrency,
    showImportView,
    setImportToken,
    t,
  ])

  return <>{getCurrencyListRows()}</>
}

export default CurrencyCross
