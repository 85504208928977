import { ChainId, isChainBitcoin } from '@pancakeswap/chains'
import { useTranslation } from '@pancakeswap/localization'
import { Button, CircleLoader, Row, useModal, useModalV2 } from '@pancakeswap/uikit'
import ConnectBitcoinWalletButton from 'components/ConnectBitcoinWalletButton'
import ConnectWalletButton from 'components/ConnectWalletButton'
import WalletModal, { WalletView } from 'components/Menu/UserMenu/WalletModal'
import { useBitcoinAccount } from 'contexts/ZetaContext/hook'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useApproveCrossSwapCallback } from 'hooks/useApproveCrossSwapCallback'
import React, { useCallback, useState } from 'react'
import { useHandleCrossSwap } from 'state/crossSwap/hooks'
import { CrossSwapTrade } from 'state/crossSwap/types'
import { config } from 'utils/wagmi'
import { Address } from 'viem'
import { useSwitchChain } from 'wagmi'
import ConfirmedHistoryModal from './ConfirmedHistoryModal'

const CrossSwapAction: React.FC<{
  trade: CrossSwapTrade
  inputChainId: ChainId
  outputChainId: ChainId
  inputError?: string
}> = ({ inputChainId, outputChainId, trade, inputError }) => {
  const { t } = useTranslation()
  const bitcoinAccount = useBitcoinAccount()
  const { account, chainId } = useActiveWeb3React()
  const { switchChain } = useSwitchChain({ config })
  const confirmedHistoryModal = useModalV2()
  const [onPresentTransactionModal] = useModal(<WalletModal initialView={WalletView.CROSS_TRANSACTIONS} />)

  const [swapping, setSwapping] = useState<boolean>(false)

  const { showCrossSwapApproveFlow, isPending, approveCrossSwapCallback } = useApproveCrossSwapCallback(trade)

  const handleCrossSwap = useHandleCrossSwap({
    bitcoinAccount,
    evmAccount: account as Address,
    trade,
  })

  const handleSwitchChain = useCallback(() => switchChain({ chainId: inputChainId }), [inputChainId, switchChain])

  const handleCrossSwapCallback = useCallback(async () => {
    setSwapping(true)
    return handleCrossSwap()
      ?.then((hash) => {
        if (hash && confirmedHistoryModal) {
          confirmedHistoryModal.onOpen()
        }
        setSwapping(false)
      })
      ?.catch(() => setSwapping(false))
  }, [confirmedHistoryModal, handleCrossSwap])

  const isContainBitcoinChain = isChainBitcoin(inputChainId) || isChainBitcoin(outputChainId)
  const isWrongChain = inputChainId && !isChainBitcoin(inputChainId) && chainId !== inputChainId

  const isAuth = account && (isContainBitcoinChain ? bitcoinAccount : true)
  const buttonDisabled = !!inputError || isPending

  return (
    <>
      <ConfirmedHistoryModal onCallback={onPresentTransactionModal} {...confirmedHistoryModal} />

      {isAuth ? (
        isWrongChain ? (
          <Button scale="lg" width="100%" onClick={handleSwitchChain}>
            Switch to chain
          </Button>
        ) : inputError ? (
          <Button scale="lg" width="100%" disabled>
            {inputError}
          </Button>
        ) : showCrossSwapApproveFlow ? (
          <Button scale="lg" width="100%" onClick={approveCrossSwapCallback} disabled={buttonDisabled}>
            {isPending ? (
              <Row justifyContent="center">
                {t('Enabling')}{' '}
                <CircleLoader
                  style={{
                    marginLeft: '8px',
                  }}
                />
              </Row>
            ) : (
              t('Enable')
            )}
          </Button>
        ) : (
          <Button scale="lg" width="100%" onClick={handleCrossSwapCallback} disabled={swapping}>
            {swapping ? (
              <>
                Swaping{' '}
                <CircleLoader
                  style={{
                    marginLeft: '4px',
                  }}
                />
              </>
            ) : (
              <>Swap</>
            )}
          </Button>
        )
      ) : !account ? (
        <ConnectWalletButton scale="lg" width="100%">
          Connect Wallet
        </ConnectWalletButton>
      ) : !bitcoinAccount && isContainBitcoinChain ? (
        <ConnectBitcoinWalletButton scale="lg">Connect BTC Wallet</ConnectBitcoinWalletButton>
      ) : (
        <></>
      )}
    </>
  )
}

export default CrossSwapAction
