import { ColumnCenter, Modal, ModalV2, ModalV2Props, Text, XdefiIcon } from '@pancakeswap/uikit'
import { WalletBitcoinConnectorEnums, useXFI } from 'hooks/WalletBitcoinProvider/useWalletBitcoinProviders'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { useAuthBitcoin } from 'hooks/useAuthBitcoin'
import React, { useCallback } from 'react'
import { XdefiConnectButton } from 'views/CrossSwap/styles'

const ConnectBitcoinWalletModal: React.FC<ModalV2Props> = ({ onDismiss, ...props }) => {
  const { login } = useAuthBitcoin()
  const { chainId } = useActiveChainId()
  const xfi = useXFI()
  // const unisat = useUnisat()
  // const xver = useXverse()

  const handleConnect = useCallback(
    async (wallet: WalletBitcoinConnectorEnums) => {
      await login(wallet, chainId)

      if (onDismiss) onDismiss()
    },
    [chainId, login, onDismiss],
  )

  return (
    <ModalV2 {...props}>
      <Modal onDismiss={onDismiss} title="Connect Xdefi wallet" maxWidth={['100%', '', '', 450]} minHeight={200}>
        <ColumnCenter>
          <XdefiConnectButton
            onClick={() => {
              if (xfi) {
                handleConnect(WalletBitcoinConnectorEnums.XFI)
              } else {
                window.open('https://www.xdefi.io/', '_target')
              }
            }}
          >
            XDEFI <XdefiIcon width={40} height={40} />
          </XdefiConnectButton>
        </ColumnCenter>
        {/* {unisat ? (
        <XdefiConnectButton onClick={() => handleConnect(WalletBitcoinConnectorEnums.UniSat)}>
          Unisat <img src="/images/wallets/unisat-wallet.svg" alt="wallet-icon" />
        </XdefiConnectButton>
      ) : (
        <XdefiConnectButton
          as="a"
          target="_"
          href="https://chromewebstore.google.com/detail/unisat-wallet/ppbibelpcjmhbdihakflkdcoccbgbkpo"
        >
          Unisat <img src="/images/wallets/unisat-wallet.svg" alt="wallet-icon" />
        </XdefiConnectButton>
      )}

      {xver ? (
        <XdefiConnectButton onClick={() => handleConnect(WalletBitcoinConnectorEnums.Xverse)}>
          Xverse <img src="/images/wallets/xverse-wallet.svg" alt="wallet-icon" />
        </XdefiConnectButton>
      ) : (
        <XdefiConnectButton
          as="a"
          target="_"
          href="https://chromewebstore.google.com/detail/xverse-wallet/idnnbdplmphpflfnlkomgpfbpcgelopg"
        >
          Xverse <img src="/images/wallets/xverse-wallet.svg" alt="wallet-icon" />
        </XdefiConnectButton>
      )} */}

        <Text fontSize="12px" fontStyle="italic" color="black" mt="8px">
          Wallets are provided by External Providers and by selecting you agree to Terms of those Providers. Your access
          to the wallet might be reliant on the External Provider being operational.
        </Text>
      </Modal>
    </ModalV2>
  )
}

export default ConnectBitcoinWalletModal
