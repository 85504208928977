import { BoxProps, Column } from '@pancakeswap/uikit'
import { PropsWithChildren, memo } from 'react'

import { Wrapper } from '../../components/styleds'

export const FormContainer = memo(function FormContainer({ children, ...props }: PropsWithChildren<BoxProps>) {
  return (
    <Wrapper id="swap-page" style={{ minHeight: '412px' }} {...props}>
      <Column gap="sm">{children}</Column>
    </Wrapper>
  )
})
