import { ChainId, isChainBitcoin, isChainZeta } from '@pancakeswap/chains'
import { Currency, CurrencyAmount, Native, Token } from '@pancakeswap/sdk'
import { ZRC20, ZRC_NATIVE } from '@pancakeswap/tokens'
import { MethodCrossSwap, TokenForeignTypeEnums } from 'config/constants/cross-swap'
import { getForeignCoins } from 'hooks/Tokens'
import { Field } from 'state/swap/actions'
import { safeGetAddress, safeGetBitcoinAddress } from 'utils'
import { getZetaChainByAnotherChain } from 'utils/wagmi'
import { Address } from 'viem'

export interface CrossSwapState {
  readonly typedValue: string
  readonly [Field.INPUT]: {
    readonly currencyId: string | undefined

    readonly chainId: ChainId
  }
  readonly [Field.OUTPUT]: {
    readonly currencyId: string | undefined

    readonly chainId: ChainId
  }

  readonly transactions: any[] //
}
export interface ICrossSwapTrade {
  inputCurrency?: Currency
  inputAmount: string

  outputCurrency?: Currency
  outputAmount: string

  slippage: number

  recipient: string
  evmAccount: Address
  bitcoinAccount: string
}

const compareAddress = (addressA, addressB) => addressA?.toLowerCase() === addressB?.toLowerCase()

export const getZrc20ByCurrency = (token: Currency): Token | undefined => {
  if (isChainZeta(token.chainId)) {
    return token.wrapped
  }

  const zrc20 = token.isNative ? ZRC_NATIVE[token.chainId] : ZRC20[token.chainId][(token as Token).address]

  if (!zrc20) {
    console.error('ZRC20', token, ZRC20[token.chainId])
  }
  return zrc20
}

export class CrossSwapTrade {
  public readonly inputCurrencyAmount: CurrencyAmount<Currency> | undefined

  public readonly outputCurrencyAmount: CurrencyAmount<Currency> | undefined

  // because if user select native, i can get native of that token
  public readonly recipient: Address | string

  public readonly slippage: number

  constructor(args: ICrossSwapTrade) {
    this.slippage = args.slippage

    const { recipient: recipientArgs, bitcoinAccount: bitcoinAccountArgs, evmAccount: evmAccountArgs } = args

    const evmAccount =
      (safeGetAddress(recipientArgs) && recipientArgs) || (safeGetAddress(evmAccountArgs) && evmAccountArgs) || ''

    const bitcoinAccount =
      (safeGetBitcoinAddress(recipientArgs) && recipientArgs) ||
      (safeGetBitcoinAddress(bitcoinAccountArgs) && bitcoinAccountArgs) ||
      ''

    this.recipient = args.outputCurrency
      ? isChainBitcoin(args.outputCurrency.chainId)
        ? bitcoinAccount
        : evmAccount
      : ''

    this.inputCurrencyAmount = args.inputCurrency && CurrencyAmount.fromRawAmount(args.inputCurrency, args.inputAmount)
    this.outputCurrencyAmount =
      args.outputCurrency && CurrencyAmount.fromRawAmount(args.outputCurrency, args.outputAmount)
  }

  public get methodSwap(): MethodCrossSwap | undefined | null {
    if (!this.inputCurrencyAmount?.currency || !this.outputCurrencyAmount?.currency) return undefined

    return getMethodCrossChain(this.inputCurrencyAmount.currency, this.outputCurrencyAmount.currency)
  }
}

export const validateNativeSymbol = (native: string, symbol: string) => {
  const symbolNative = native.charAt(0).toUpperCase() === 'T' ? native.replace('t', '') : native
  return new RegExp(`^t?${symbolNative?.toUpperCase()}$`, 'i').test(symbol.toUpperCase())
}

export const validateSameToken = ({
  inputCurrency,
  outputCurrency,
}: {
  inputCurrency: Currency
  outputCurrency: Currency
}) => {
  const isInputNative = inputCurrency.isNative
  const isOutputNative = outputCurrency.isNative
  const inputChain = inputCurrency.chainId
  const outputChain = outputCurrency.chainId
  const foreigns = getForeignCoins(inputChain)

  const inputAddress = inputCurrency.wrapped?.address?.toLowerCase()
  const outputAddress = outputCurrency.wrapped?.address?.toLowerCase()

  // ZRC20 ZETA -> Native Other chain
  if (isChainZeta(inputChain) && !isInputNative && isOutputNative && inputAddress) {
    return !!foreigns.find((token) => {
      return (
        token.foreignChainId === outputChain &&
        compareAddress(inputAddress, token.zrc20Address) &&
        token.type === TokenForeignTypeEnums.Gas
      )
    })
  }

  // Native Other chain -> ZRC20 ZETA
  if (isChainZeta(outputChain) && isInputNative && !isOutputNative && outputAddress) {
    return !!foreigns.find((token) => {
      return (
        token.foreignChainId === inputChain &&
        compareAddress(outputAddress, token.zrc20Address) &&
        token.type === TokenForeignTypeEnums.Gas
      )
    })
  }

  // ZRC20 ZETA -> ERC20 Other chain
  if (isChainZeta(inputChain) && !isInputNative && !isOutputNative && inputAddress && outputAddress) {
    return !!foreigns.find((token) => {
      return (
        token.foreignChainId === outputChain &&
        compareAddress(inputAddress, token.zrc20Address) &&
        compareAddress(outputAddress, token.address)
      )
    })
  }

  // ERC20 Other chain -> ZRC20 ZETA
  if (isChainZeta(outputChain) && !isInputNative && !isOutputNative && inputAddress && outputAddress) {
    return !!foreigns.find((token) => {
      return (
        token.foreignChainId === inputChain &&
        compareAddress(inputAddress, token.address) &&
        compareAddress(outputAddress, token.zrc20Address)
      )
    })
  }

  return false
}

export const isBTC = (inputCurrency: Currency, inputChain: ChainId) => {
  if (isChainBitcoin(inputChain)) return true

  const crossTokens = getForeignCoins(inputChain)
  return !!crossTokens.find((token) => {
    return (
      token.type === TokenForeignTypeEnums.Gas &&
      isChainBitcoin(token.foreignChainId) &&
      compareAddress(inputCurrency.wrapped?.address, token.zrc20Address)
    )
  })
}

const validateBridgeToken = (inputCurrency: Currency, outputCurrency: Currency) => {
  const crossTokens = getForeignCoins(inputCurrency.chainId)
  return !!crossTokens.find((token) => {
    return (
      token.foreignChainId === inputCurrency.chainId &&
      compareAddress(inputCurrency?.wrapped.address, token.address) &&
      compareAddress(outputCurrency?.wrapped.address, token.zrc20Address)
    )
  })
}

export const isErc20 = (inputCurrency: Currency, inputChain: ChainId) => {
  if (isChainZeta(inputChain) && inputCurrency.isNative) return false

  const crossTokens = getForeignCoins(inputChain)

  return !!crossTokens.find((token) => {
    return (
      token.type === TokenForeignTypeEnums.ERC20 && compareAddress((inputCurrency as Token)?.address, token.address)
    )
  })
}

export const isStableZrc20 = (inputCurrency: Currency, inputChain: ChainId) => {
  if (!isChainZeta(inputChain) || inputCurrency.isNative) return false

  const crossTokens = getForeignCoins(inputChain)

  return !!crossTokens.find((token) => {
    return (
      token.type === TokenForeignTypeEnums.ERC20 &&
      compareAddress((inputCurrency as Token)?.address, token.zrc20Address)
    )
  })
}

export const isNativeZrc20 = (inputCurrency: Currency, inputChain: ChainId) => {
  if (!isChainZeta(inputChain) || inputCurrency.isNative) return false

  const crossTokens = getForeignCoins(inputChain)

  return !!crossTokens.find((token) => {
    return (
      token.type === TokenForeignTypeEnums.Gas && compareAddress((inputCurrency as Token)?.address, token.zrc20Address)
    )
  })
}

export const getMethodCrossChain = (
  inputCurrency: Currency,
  outputCurrency: Currency,
): MethodCrossSwap | undefined | null => {
  if (!inputCurrency || !outputCurrency) return undefined

  const isInputNative = inputCurrency.isNative
  const isOutputNative = outputCurrency.isNative
  const inputChain = inputCurrency.chainId
  const outputChain = outputCurrency.chainId

  const inputSymbol = inputCurrency.symbol?.toUpperCase() || ''
  const outputSymbol = outputCurrency.symbol?.toUpperCase() || ''

  const nativeCurrency = Native.onChain(getZetaChainByAnotherChain(inputChain))
  const wrapNativeCurrency = Native.onChain(getZetaChainByAnotherChain(inputChain)).wrapped

  const nativeSymbol = nativeCurrency.symbol.toUpperCase()
  const wrapNativeSymbol = wrapNativeCurrency.symbol.toUpperCase()

  const fromZETAorWZETA = [nativeSymbol, wrapNativeSymbol].includes(inputSymbol)

  const fromZetaChain = isChainZeta(inputChain)
  const fromBTC = isBTC(inputCurrency, inputChain)
  const toBTC = isBTC(outputCurrency, outputChain)

  const toZETAorWZETA = [nativeSymbol, wrapNativeSymbol].includes(outputSymbol)
  const toZetaChain = isChainZeta(outputChain)

  const fromErc20 = isErc20(inputCurrency, inputChain)
  const toErc20 = isErc20(outputCurrency, outputChain)

  const fromStableZrc20 = isStableZrc20(inputCurrency, inputChain)
  const toStableZrc20 = isStableZrc20(outputCurrency, outputChain)

  const fromNativeZrc20 = isNativeZrc20(inputCurrency, inputChain)
  const toNativeZrc20 = isNativeZrc20(outputCurrency, outputChain)

  const fromBTCtoBTC = fromBTC && toBTC

  const toBitcoin = isChainBitcoin(outputChain)
  const toNative = Native.onChain(outputChain).equals(outputCurrency)

  const sameToken = validateSameToken({
    inputCurrency,
    outputCurrency,
  })

  const sameChain = inputChain === outputChain
  const fromToZetaChain = fromZetaChain || toZetaChain
  const fromToZETAorWZETA = fromZETAorWZETA || toZETAorWZETA

  const isFromErc20ToZrc20 = fromErc20 && toStableZrc20
  const isFromZrc20ToErc20 = fromNativeZrc20 && toErc20
  const isFromStableZrc20ToErc20 = fromStableZrc20 && toErc20

  const isBridgeERC20 = isFromErc20ToZrc20 && validateBridgeToken(inputCurrency as Token, outputCurrency as Token)
  const isBridgeZRC20 = isFromStableZrc20ToErc20 && validateBridgeToken(outputCurrency as Token, inputCurrency as Token)

  const isFromNativeToStableZrc20 = isInputNative && toStableZrc20 && !toBTC
  const isFromNativeToErc20 = isInputNative && toErc20 && !toBTC

  const isFromErc20ToAnotherZrc20 = isFromErc20ToZrc20 && !isBridgeERC20

  const isFromStableZrc20ToAnotherErc20 = isFromStableZrc20ToErc20 && !isBridgeZRC20
  const isFromNativeZrc20ToAnotherErc20 = isFromZrc20ToErc20 && !isBridgeZRC20

  const fromOrToIsNative = isInputNative || isOutputNative

  // USDT (ETH) -> USDT (ZETA), // USDC (BSC) -> USDC(ZETA)
  if (sameToken && !fromZetaChain && toZetaChain && isBridgeERC20 && !fromOrToIsNative && !fromBTC)
    return MethodCrossSwap.depositERC20

  // BTC (BITCOIN) -> BNB(BSC) (Other Chain, token NATIVE)
  if (fromBTC && !toBitcoin && !fromToZetaChain && !toErc20 && !toStableZrc20 && !toZETAorWZETA)
    return MethodCrossSwap.crossChainSwapBTC

  // BTC (BITCOIN) -> BNB(ZETA) (to Zeta, token ZRC20)
  if (fromBTC && !fromZetaChain && toZetaChain && !toErc20 && !toStableZrc20 && !toZETAorWZETA && !fromBTCtoBTC)
    return MethodCrossSwap.crossChainSwapBTCTransfer

  // BTC (BITCOIN) -> ZETA(ZETA) (to Zeta, token NATIVE)
  if (fromBTC && !fromZetaChain && toZetaChain && (toZETAorWZETA || toBTC))
    return MethodCrossSwap.crossChainSwapBTCTransferNative

  /*
    ETH (ZETA) -> BNB(BSC)
    ETH (ZETA) -> MATIC(POLYGON)
    From ZRC20 ZETA to another chain (no match between ZRC20 And another chain NATIVE)
  */
  if (
    !isFromNativeToStableZrc20 &&
    !isFromNativeToErc20 &&
    !isFromErc20ToAnotherZrc20 &&
    !isFromStableZrc20ToAnotherErc20 &&
    !isFromNativeZrc20ToAnotherErc20 &&
    !toZetaChain &&
    !fromToZETAorWZETA &&
    !sameChain &&
    fromZetaChain
  )
    return MethodCrossSwap.crossChainZeta

  /*
    BNB (BSC) -> ETH(ETH),
    ETH (ETH) -> BNB(BSC)
    BNB (BSC) -> MATIC(POLYGON)
    BNB (BSC) -> BTC(BITCOIN),
    -> from NATIVE to another chain, but not to ZETA (no match between ZRC20 And another chain NATIVE)
  */
  if (
    !isFromNativeToStableZrc20 &&
    !isFromNativeToErc20 &&
    !isFromErc20ToAnotherZrc20 &&
    !toZetaChain &&
    !fromToZETAorWZETA &&
    !sameChain &&
    isInputNative
  )
    return MethodCrossSwap.crossChainSwap

  /*
    ETH (ETH) -> BNB(ZETA)
    ETH (ETH) -> MATIC(ZETA)

    -> from NATIVE to ZETA Chain (no match between ZRC20 And another chain NATIVE)
  */

  if (
    isInputNative &&
    toNativeZrc20 &&
    !isFromNativeToStableZrc20 &&
    !isFromNativeToErc20 &&
    !isFromErc20ToAnotherZrc20 &&
    !fromZetaChain &&
    !fromToZETAorWZETA &&
    !fromBTCtoBTC &&
    toZetaChain
  )
    return MethodCrossSwap.crossChainSwapTransfer

  /*
    ETH (ETH) -> ZETA(ZETA)
    BNB (BSC) -> ZETA(ZETA)
    -> from NATIVE to NATIVE (no match between ZRC20 And another chain NATIVE)
  */
  if (!fromZetaChain && !fromErc20 && !fromBTCtoBTC && toZetaChain && toZETAorWZETA)
    return MethodCrossSwap.crossChainSwapNativeTransfer

  /*
   ZETA(ZETA) ->  ETH (ETH) 
   ZETA(ZETA) ->  BNB (BSC) 
   ZETA(ZETA) ->  BTC (BITCOIN) 
   -> from NATIVE to NATIVE (no match between ZRC20 And another chain NATIVE)
  */
  if (fromZetaChain && fromZETAorWZETA && toNative) return MethodCrossSwap.crossChainSwapNative

  return null
}
