import { isChainBitcoin } from '@pancakeswap/chains'
import { MethodCrossSwap } from 'config/constants/cross-swap'
import { useMemo } from 'react'
import { CrossSwapTrade } from 'state/crossSwap/types'
import { getErc20CustodyAddress, getOmniChainAddress } from 'utils/addressHelpers'
import { ApprovalState, useApproveCallback } from './useApproveCallback'

export const useApproveCrossSwapCallback = (trade: CrossSwapTrade) => {
  const tokenAmountApprove = useMemo(() => {
    if (!trade.inputCurrencyAmount || !trade.methodSwap)
      return {
        currencyAmount: trade.inputCurrencyAmount,
        spender: '',
      }

    if ([MethodCrossSwap.depositERC20].includes(trade.methodSwap)) {
      return {
        currencyAmount: trade.inputCurrencyAmount,
        spender: getErc20CustodyAddress(trade.inputCurrencyAmount?.currency.chainId),
      }
    }

    if (
      [MethodCrossSwap.crossChainSwap, MethodCrossSwap.crossChainSwapTransfer, MethodCrossSwap.crossChainZeta].includes(
        trade.methodSwap,
      ) &&
      trade.inputCurrencyAmount.currency.isToken &&
      !isChainBitcoin(trade.inputCurrencyAmount.currency.chainId)
    ) {
      return {
        currencyAmount: trade.inputCurrencyAmount,
        spender: getOmniChainAddress(trade.inputCurrencyAmount.currency.chainId),
      }
    }

    return {
      currencyAmount: trade.inputCurrencyAmount,
      spender: '',
    }
  }, [trade])

  const { approvalState, approveCallback } = useApproveCallback(
    tokenAmountApprove.currencyAmount,
    tokenAmountApprove.spender,
  )

  const showApproveFlow = approvalState === ApprovalState.NOT_APPROVED || approvalState === ApprovalState.PENDING
  const isPending = approvalState === ApprovalState.PENDING

  return {
    approvalCrossSwapState: approvalState,
    approveCrossSwapCallback: approveCallback,
    showCrossSwapApproveFlow: showApproveFlow,
    isPending,
  }
}
