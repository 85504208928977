import { ChainId } from '@pancakeswap/chains'
import { useTranslation } from '@pancakeswap/localization'
import { Native } from '@pancakeswap/sdk'
import { Currency, Percent } from '@pancakeswap/swap-sdk-core'
import { useMatchBreakpoints } from '@pancakeswap/uikit'
import CurrencyChainInputPanel from 'components/CurrencyInputPanel/CurrencyChainInputPanel'
import { useBitcoinAccount } from 'contexts/ZetaContext/hook'
import { useCurrencyCross } from 'hooks/Tokens'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useCallback, useMemo } from 'react'
import { useCrossSwapActionHandlers, useCrossSwapState, useDerivedCrossSwapInfo } from 'state/crossSwap/hooks'
import { Field } from 'state/swap/actions'
import { useCurrencyCrossChainBalances } from 'state/wallet/hooks'
import { maxAmountSpend } from 'utils/maxAmountSpend'
import { FormContainer } from 'views/Swap/V3Swap/components'
import CrossSwapAction from './CrossSwapAction'
import CrossSwapFeeDisplay from './CrossSwapFeeDisplay'
import { FlipButton } from './FlipButton'
import { FormHeader } from './FormHeader'
import RecipientOptional from './RecipientOptional'

const CrossSwapForm = () => {
  const bitcoinAccount = useBitcoinAccount()
  const { account } = useActiveWeb3React()
  const { t } = useTranslation()
  const { onCurrencySelection, onUserInput } = useCrossSwapActionHandlers()
  const { isMobile } = useMatchBreakpoints()

  const {
    independentField,
    typedValue,
    [Field.INPUT]: { currencyId: inputCurrencyId, chainId: inputChainId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId, chainId: outputChainId },
  } = useCrossSwapState()

  const inputCurrency = useCurrencyCross(inputCurrencyId, inputChainId)
  const outputCurrency = useCurrencyCross(outputCurrencyId, outputChainId)

  const handleTypeInput = useCallback((value: string) => onUserInput(Field.INPUT, value), [onUserInput])
  const handleTypeOutput = useCallback((value: string) => onUserInput(Field.OUTPUT, value), [onUserInput])

  const [inputBalance] = useCurrencyCrossChainBalances(account, bitcoinAccount, [inputCurrency])
  const maxAmountInput = useMemo(() => maxAmountSpend(inputBalance), [inputBalance])

  const { trade, fee, inputError, feeError } = useDerivedCrossSwapInfo(
    independentField,
    typedValue,
    inputCurrency,
    outputCurrency,
  )

  const handleInputSelectChain = useCallback(
    (chain: ChainId) => onCurrencySelection(Field.INPUT, chain, Native.onChain(chain)),
    [onCurrencySelection],
  )
  const handleOutputSelectChain = useCallback(
    (chain: ChainId) => onCurrencySelection(Field.OUTPUT, chain, Native.onChain(chain)),
    [onCurrencySelection],
  )

  const handleInputSelect = useCallback(
    (newCurrency: Currency) => onCurrencySelection(Field.INPUT, inputChainId, newCurrency),
    [inputChainId, onCurrencySelection],
  )
  const handleOutputSelect = useCallback(
    (newCurrency: Currency) => onCurrencySelection(Field.OUTPUT, outputChainId, newCurrency),
    [onCurrencySelection, outputChainId],
  )

  const handlePercentInput = useCallback(
    (percent: number) =>
      maxAmountInput && onUserInput(Field.INPUT, maxAmountInput.multiply(new Percent(percent, 100)).toExact()),
    [maxAmountInput, onUserInput],
  )
  const handleMaxInput = useCallback(
    () => maxAmountInput && onUserInput(Field.INPUT, maxAmountInput.toExact()),
    [maxAmountInput, onUserInput],
  )

  const isExactIn = independentField === Field.INPUT
  const dependentField = isExactIn ? Field.OUTPUT : Field.INPUT

  const formattedAmounts = useMemo(
    () => ({
      [independentField]: isExactIn ? typedValue || '' : trade?.inputCurrencyAmount?.toExact() || '',
      [dependentField]: isExactIn ? (typedValue ? trade?.outputCurrencyAmount?.toExact() : '' || '') : typedValue || '',
    }),
    [dependentField, independentField, isExactIn, trade, typedValue],
  )

  // const gas = useEstimateSwapCrossChain({
  //   bitcoinAccount,
  //   evmAccount: account as Address,
  //   trade,
  // })

  return (
    <>
      <FormHeader />
      <FormContainer pt="12px !important">
        <CurrencyChainInputPanel
          id="swap-currency-input"
          showUSDPrice
          showMaxButton
          showQuickInputButton={!isMobile}
          title={t('From')}
          label="From"
          value={formattedAmounts[independentField]}
          maxAmount={maxAmountInput}
          currency={inputCurrency}
          selectedChainId={inputChainId}
          onUserInput={handleTypeInput}
          onPercentInput={handlePercentInput}
          onMax={handleMaxInput}
          onCurrencySelect={handleInputSelect}
          onChainSelect={handleInputSelectChain}
          otherCurrency={outputCurrency}
        />
        <FlipButton />
        <CurrencyChainInputPanel
          id="swap-currency-output"
          showUSDPrice
          showMaxButton={false}
          showQuickInputButton
          title={t('To')}
          label="To"
          value={formattedAmounts[dependentField]}
          currency={outputCurrency}
          selectedChainId={outputChainId}
          onUserInput={handleTypeOutput}
          onCurrencySelect={handleOutputSelect}
          onChainSelect={handleOutputSelectChain}
          otherCurrency={outputCurrency}
        />

        <RecipientOptional chainId={outputChainId} />
        <CrossSwapFeeDisplay my="8px" fee={fee} feeError={feeError} />

        <CrossSwapAction
          inputChainId={inputChainId}
          outputChainId={outputChainId}
          trade={trade}
          inputError={inputError}
        />
      </FormContainer>
    </>
  )
}
export default CrossSwapForm
