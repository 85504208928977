import { BoxProps, FlexGap, GasIcon, Text } from '@pancakeswap/uikit'
import { IconWrapper } from '../styles'

const CrossSwapFeeDisplay: React.FC<{ fee: number; feeError?: string } & BoxProps> = ({ fee, feeError, ...props }) => {
  return (
    <FlexGap flexDirection="column" {...props}>
      <>
        {fee && (
          <FlexGap>
            <IconWrapper width="25px" height="25px" bg="text" mr="4px">
              <GasIcon />
            </IconWrapper>
            <Text color="black" bold>
              ~${`${fee?.toFixed(2) || 0}`}
            </Text>
          </FlexGap>
        )}
        {feeError && (
          <Text mt="8px" small fontWeight="400" color="#CC6228" bold>
            {feeError}
          </Text>
        )}
      </>
    </FlexGap>
  )
}

export default CrossSwapFeeDisplay
