import { ChainId } from '@pancakeswap/chains'
import { ZERO } from '@pancakeswap/swap-sdk-core'
import { useQuery } from '@tanstack/react-query'
import BigNumber from 'bignumber.js'
import { FAST_INTERVAL } from 'config/constants'
import { getBitcoinBalance } from 'contexts/ZetaContext/helpers'
import { useMemo } from 'react'

// for migration to bignumber.js to avoid breaking changes
export const useBitcoinBalance = (bitcoinAccount: string | undefined, chainId: ChainId): string => {
  const { data } = useQuery<BigNumber | null, Error>({
    queryKey: ['bitcoinAccount'],
    queryFn: async () => {
      if (!bitcoinAccount) return null
      const balance = await getBitcoinBalance(bitcoinAccount, chainId)
      return new BigNumber(balance.quotient.toString())
    },
    enabled: !!bitcoinAccount,
    staleTime: FAST_INTERVAL,
    refetchInterval: FAST_INTERVAL,
  })

  return useMemo(() => (data ?? ZERO).toString(), [data])
}
