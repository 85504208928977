import { useCallback } from 'react'
import { GetFunctionArgs, InferFunctionName } from 'state/multicall/types'
import { SendTransactionResult } from 'state/transactions/hooks'
import { useGasPrice } from 'state/user/hooks'
import { calculateGasMargin } from 'utils'
import { config, publicClient } from 'utils/wagmi'
import type { EstimateContractGasParameters, Hash } from 'viem'
import { Abi, Account, Address, CallParameters, Chain } from 'viem'
import { useWalletClient } from 'wagmi'
import { writeContract } from 'wagmi/actions'
import { useActiveChainId } from './useActiveChainId'

export function useCallWithGasPrice() {
  const gasPrice = useGasPrice()
  const { chainId } = useActiveChainId()
  const { data: walletClient } = useWalletClient()

  const callWithGasPriceWithSimulate = useCallback(
    async <
      TAbi extends Abi | unknown[],
      TFunctionName extends string = string,
      _FunctionName = InferFunctionName<TAbi, TFunctionName>,
      Args = TFunctionName extends string
        ? GetFunctionArgs<TAbi, TFunctionName>['args']
        : _FunctionName extends string
        ? GetFunctionArgs<TAbi, _FunctionName>['args']
        : never,
    >(
      contract: { abi: TAbi; account: Account | undefined; chain: Chain | undefined; address: Address } | null,
      methodName: InferFunctionName<TAbi, TFunctionName>,
      methodArgs?: Args extends never ? undefined : Args,
      overrides?: Omit<CallParameters, 'chain' | 'to' | 'data'>,
    ): Promise<SendTransactionResult> => {
      if (!contract) {
        throw new Error('No valid contract')
      }
      if (!walletClient) {
        throw new Error('No valid wallet connect')
      }
      const { gas: gas_, ...overrides_ } = overrides || {}
      let gas = gas_
      if (!gas) {
        gas = await publicClient({ chainId })?.estimateContractGas({
          abi: contract.abi,
          address: contract.address,
          account: walletClient.account,
          functionName: methodName,
          args: methodArgs,
          value: 0n,
          ...overrides_,
        } as unknown as EstimateContractGasParameters)
      }

      const res = await writeContract(config, {
        abi: contract.abi,
        address: contract.address,
        account: walletClient.account,
        functionName: methodName,
        args: methodArgs as any,
        gasPrice,
        gas: calculateGasMargin(gas as bigint),
        ...(overrides_ as any),
      })

      return { hash: res as Hash }
    },
    [chainId, gasPrice, walletClient],
  )

  return { callWithGasPrice: callWithGasPriceWithSimulate }
}
