import { ChainId } from '@pancakeswap/chains'
import { createAction } from '@reduxjs/toolkit'
import { Field } from 'state/swap/actions'

export const selectCurrency = createAction<{ field: Field; currencyId: string; chainId: ChainId }>(
  'crossSwap/selectCurrency',
)

export const switchCurrencies = createAction<void>('crossSwap/switchCurrencies')
export const typeInput = createAction<{ field: Field; typedValue: string }>('crossSwap/typeInput')
export const replaceCrossSwapState = createAction<{
  field: Field
  typedValue: string

  inputChainId: ChainId
  inputCurrencyId?: string

  outputCurrencyId?: string
  outputChainId: ChainId

  recipient: string | null
}>('crossSwap/replaceCrossSwapState')

export const setRecipient = createAction<{ recipient: string | null }>('crossSwap/setRecipient')
